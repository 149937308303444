<template>
  <button 
    class="py-3 flex items-center justify-center rounded-full shadow-md focus:shadow-2xl text-white" 
    :class="buttonType"
    @click.prevent="$emit('button-click')">
      <span v-if="!loading" class="flex items-center justify-center">
        <slot name="icon"></slot>
        <span class="mx-2">{{ text }}</span>
      </span>
      <span v-else class="mx-2">
        <svg class="animate-spin h-5 w-5 mx-2" viewBox="0 0 24 24">
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </span>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    text: String,
    type: String,
    loading: Boolean
  },
  computed: {
    buttonType() {
      let buttonStyle
      if (this.type === 'success') {
        buttonStyle = 'button-green'
      } else if (this.type === 'primary') {
        buttonStyle = 'bg-blue-600'
      } else if (this.type === 'danger') {
        buttonStyle = 'bg-red-600'
      } else if (this.type === 'cancel') {
        buttonStyle = 'bg-gray-600'
      }
      return buttonStyle
    }
  }
}
</script>

<style lang="sass" scoped>
.button-green
  background-color: #4cb050
</style>