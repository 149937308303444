/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    filteredData: null,
    approvalList: null,
    permissionTypeList: null,
    annualLeave: null
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setFilteredData(state, data) {
      state.filteredData = data
    },
    setApprovalList(state, data) {
      state.approvalList = data
    },
    setPermissionTypeList(state, data) {
      state.permissionTypeList = data
    },
    setAnnualLeave(state, data) {
      state.annualLeave = data
    },
    filterPermissionList(state, data) {
      state.filteredData = state.data.filter(permission => permission.status === data)
    }
  },
  actions: {
    createPermission({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}permission`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getPermission({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}permission`, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data.data.permission_list)
          commit('setFilteredData', res.data.data.permission_list)
          commit('setApprovalList', res.data.data.approval_list)
          commit('setPermissionTypeList', res.data.data.permission_type_list)
          commit('setAnnualLeave', res.data.data.cuti_tahunan_info)
          resolve(res)
        })
        .catch(err => reject(err))
      }) 
    },
    getPermissionDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}permission/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deletePermission({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}permission/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    }
  },
  getters: {
  }
})