import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import permission from './modules/permission'
import approval from './modules/approval'
import slip from './modules/slip'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    permission,
    approval,
    slip
  }
})