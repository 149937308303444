<template>
  <div class="h-auto w-full py-2 px-4 grid grid-cols-12 gap-5 bg-white rounded shadow-md border">
    <div class="col-span-12 lg:col-span-6 gap-3 w-full flex flex-col">
      <div class="flex flex-row items-center gap-2">
        <img
          src="@/assets/img/bpp-logo.png"
          alt="BPP Logo"
          class="h-10"
        >
        <div>
          <h3 class="font-bold text-lg">PT BERDIKARI PONDASI PERKASA</h3>
          <span class="uppercase text-gray-500 text-sm">Slip Gaji</span>
        </div>
      </div>
      <div class="self-end flex flex-col items-end">
        <template v-if="loading">
          <BaseSkeleton class="h-6 w-64"/>
          <BaseSkeleton class="h-6 w-72"/>
          <BaseSkeleton class="h-6 w-64"/>
        </template>
        <template v-else>
          <span class="font-bold text-blue-500 text-lg">{{ data.namaKaryawan }}</span>
          <span class="font-bold">ID Karyawan: {{ data.idKaryawan }}</span>
          <span class="text-sm">{{ data.jabatan }}</span>
        </template>
      </div>
    </div>

    <div class="col-span-12 lg:col-span-1 lg:mx-auto w-full h-0.5 lg:w-0.5 lg:h-full bg-black opacity-10" />

    <div class="col-span-12 lg:col-span-5 gap-3 w-full flex flex-col">
      <div class="flex flex-row gap-2 items-center justify-between">
        <label for="salary-period">Periode Gaji</label>
        <div class="flex flex-row items-center">
          <DatePicker
            type="month"
            v-model="selectedMonth"
            value-type="format"
            format="MM-YYYY"
            @change="getSalaryDetail"
          >
            <template #icon-calendar>
              <span></span>
            </template>
          </DatePicker>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M21 20V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2zM9 18H7v-2h2v2zm0-4H7v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm2-5H5V7h14v2z"/></svg>
        </div>
      </div>
      <div class="grid 2xl:grid-flow-col grid-cols-2 grid-rows-3 gap-x-10 gap-y-2">
        <div class="col-span-2 2xl:col-span-1">
          <div class="flex flex-row gap-2 justify-between items-center text-sm">
            <template v-if="loading">
              <BaseSkeleton class="h-4 w-full" />
              <BaseSkeleton class="h-4 w-full" />
            </template>
            <template v-else>
              <span>{{ firstPeriodSalary.title }}</span>
              <span
                class="font-bold"
                :class="!firstPeriodSalary.show && 'underline text-blue-400 cursor-pointer'"
                @click="togglePayroll('first-salary')"
              >
                {{ firstPeriodSalary.total }}
              </span>
            </template>
          </div>
        </div>
        <div class="col-span-2 2xl:col-span-1">
          <div class="flex flex-row gap-2 justify-between items-center text-sm">
            <template v-if="loading">
              <BaseSkeleton class="h-4 w-full" />
              <BaseSkeleton class="h-4 w-full" />
            </template>
            <template v-else>
              <span>{{ secondPeriodSalary.title }}</span>
              <span
                class="font-bold"
                :class="!secondPeriodSalary.show && 'underline text-blue-400 cursor-pointer'"
                @click="togglePayroll('second-salary')"
              >
                {{ secondPeriodSalary.total }}
              </span>
            </template>
          </div>
        </div>
        <div class="col-span-2 2xl:col-span-1">
          <div class="flex flex-row gap-2 justify-between items-center text-sm">
            <template v-if="loading">
              <BaseSkeleton class="h-4 w-full" />
              <BaseSkeleton class="h-4 w-full" />
            </template>
            <template v-else>
              <span>{{ data.extra.title }}</span>
              <span
                class="font-bold"
                :class="!extraSalary.show && 'underline text-blue-400 cursor-pointer'"
                @click="togglePayroll('extra')"
              >
                {{ data.extra.total }}
              </span>
            </template>
          </div>
        </div>
        <div class="col-span-2 2xl:col-span-1">
          <div class="flex flex-row gap-2 justify-between items-center text-sm">
            <template v-if="loading">
              <BaseSkeleton class="h-4 w-full" />
              <BaseSkeleton class="h-4 w-full" />
            </template>
            <template v-else>
              <span>{{ data.ptr.title }}</span>
              <span
                class="font-bold"
                :class="!ptrSalary.show && 'underline text-blue-400 cursor-pointer'"
                @click="togglePayroll('ptr')"
              >
                {{ data.ptr.total }}
              </span>
            </template>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-end text-blue-400">
        <template v-if="loading">
          <BaseSkeleton class="h-5 w-40" />
          <BaseSkeleton class="h-5 w-40" />
        </template>
        <template v-else>
          <span class="font-bold">{{ data.totalPendapatan.total }}</span>
          <span class="text-sm">{{ data.totalPendapatan.title }}</span>
        </template>
      </div>
      <div class="grid 2xl:grid-flow-col grid-cols-2 grid-rows-3 gap-x-10 gap-y-2">
        <div class="col-span-2 2xl:col-span-1">
          <div class="flex flex-row gap-2 justify-between items-center text-sm">
            <template v-if="loading">
              <BaseSkeleton class="h-4 w-full" />
              <BaseSkeleton class="h-4 w-full" />
            </template>
            <template v-else>
              <span>{{ data.deposit.title }}</span>
              <span class="font-bold">{{ data.deposit.total }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import BaseSkeleton from '../base/BaseSkeleton.vue'

export default {
  name: 'SlipSummary',
  components: {
    BaseSkeleton,
    DatePicker
  },
  computed: {
    ...mapState('slip', ['data', 'loading']),
    ...mapGetters('slip', ['salaryDetail', 'firstPeriodSalary', 'secondPeriodSalary', 'ptrSalary', 'extraSalary']),
    ...mapGetters('auth', ['userData']),
    monthOptions() {
      let date = `January ${new Date().getFullYear()}`;
      let result = [];
      const endDate = new Date(date.replace(' ', ' ,1 '));
      const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      while (endDate <= startDate) {
        result.push({
          label: startDate.toLocaleString('id', { month: 'long', year: 'numeric' }),
          value: `${startDate.getMonth() + 1}-${startDate.getFullYear()}`
        });
        startDate.setMonth(startDate.getMonth() - 1);
      }
      return result;
    }
  },
  data() {
    return {
      selectedMonth: `${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
    };
  },
  methods: {
    ...mapActions('slip', ['getSalarySlip', 'setLoading']),
    ...mapMutations('slip', ['togglePayrollDetail', 'setSalaryTitle']),
    getSalaryDetail() {
      this.getSalarySlip({
        employeeId: this.userData.employee_id,
        year: this.selectedMonth.split('-')[1],
        month: this.selectedMonth.split('-')[0]
      });
    },
    togglePayroll(payrollType) {
      this.togglePayrollDetail(payrollType)
      
      switch (payrollType) {
        case 'first-salary':
          this.setSalaryTitle(`Gaji Periode 1`)
          break
        case 'second-salary':
          this.setSalaryTitle(`Gaji Periode 2`)
          break
        case 'ptr':
          this.setSalaryTitle(`Penghasilan Tidak Rutin`)
          break
        case 'extra':
          this.setSalaryTitle(`Extra`)
          break
        default:
          break
      }
    }
  },
  created() {
    this.getSalaryDetail();
  }
}
</script>