/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: null
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.detail = data
    }
  },
  actions: {
    getApproval({ commit }, { status, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}approval?status=${status}&start_date=${new Date(Date.now() - 31 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')}&end_date=${new Date(Date.now() + 31 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')}`, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      }) 
    },
    getApprovalDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}approval/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      }) 
    },
    patchApprovalApprove({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}approval/approve`, {id}, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      }) 
    },
    patchApprovalReject({ commit }, { id, rejectedDescription, token }) {
      const data = {
        id,
        rejected_description: rejectedDescription
      }
      
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}approval/reject`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000,
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      }) 
    }
  },
  getters: {
    detail: state => state.detail
  }
})