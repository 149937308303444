<template>
  <div class="h-full w-full flex">

    <div class="h-screen w-full lg:p-3 lg:w-1/4">
      <BaseNav/>
    </div>

    <div class="h-screen w-3/4 hidden lg:flex">
      <div class="h-full w-full flex items-center justify-center">
        <div class="p-3 bg-gray-200 rounded-lg">
          <p class="text-xl font-bold">Silahkan pilih menu terlebih dahulu</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BaseNav from '@/components/base/BaseNav'

export default {
  name: 'Home',
  components: {
    BaseNav
  }
}
</script>
