import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Permission from '../views/permission/Permission.vue'
import PermissionAdd from '../views/permission/PermissionAdd.vue'
import PermissionDetail from '../views/permission/PermissionDetail.vue'
import AnnualLeaveDetail from '../views/AnnualLeaveDetail.vue'
import Approval from '../views/Approval.vue'
import ApprovalDetail from '../views/ApprovalDetail.vue'
import SlipSalary from '../views/SlipSalary.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { 
      title: 'Datangg - Login'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      title: 'Datangg',
      requiresAuth: true
    }
  },
  {
    path: '/permission',
    name: 'Permission',
    component: Permission,
    meta: { 
      title: 'Datangg - Izin',
      requiresAuth: true
    }
  },
  {
    path: '/new-permission',
    name: 'PermissionAdd',
    component: PermissionAdd,
    meta: { 
      title: 'Datangg - Permohonan Izin',
      requiresAuth: true
    }
  },
  {
    path: '/permission-detail/:id',
    name: 'PermissionDetail',
    component: PermissionDetail,
    meta: { 
      title: 'Datangg - Detail Izin',
      requiresAuth: true
    }
  },
  {
    path: '/annual-leave',
    name: 'AnnualLeaveDetail',
    component: AnnualLeaveDetail,
    meta: { 
      title: 'Datangg - Detail Cuti',
      requiresAuth: true
    }
  },
  {
    path: '/approval',
    name: 'Approval',
    component: Approval,
    meta: { 
      title: 'Datangg - Daftar Persetujuan Izin',
      requiresAuth: true
    }
  },
  {
    path: '/approval-detail/:id',
    name: 'ApprovalDetail',
    component: ApprovalDetail,
    meta: { 
      title: 'Datangg - Detail Persetujuan Izin',
      requiresAuth: true
    }
  },
  {
    path: '/slip/salary',
    name: 'SlipSalary',
    component: SlipSalary,
    meta: {
      title: 'Datangg - Slip Gaji',
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { 
      title: 'Datangg - Halaman Tidak Ditemukan',
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.getItem('token')) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => !record.meta.requiresAuth)) {
    if (sessionStorage.getItem('token')) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  next()
})

export default router
