<template>
  <div 
    class="py-2 px-4 bg-white rounded shadow-md border overflow-x-auto"
  >
    <div
      v-if="loading"
      class="h-96 w-full flex items-center justify-center"
    >
      <svg 
        class="animate-spin text-blue-600 h-10 w-10 mr-3" 
        viewBox="0 0 24 24"
      >
        <path 
          class="opacity-75" 
          fill="currentColor" 
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>  
    </div>

    <div 
      v-else-if="isSalaryFirstPeriod || isSalarySecondPeriod"
      style="min-width: 700px;"
    >
      <!-- Main detail -->
      <!-- Earnings title -->
      <div class="mb-5 p-3 text-xl font-semibold border-b-4 border-blue-600">
        Pendapatan
      </div>
  
      <!-- Remunerasi list -->
      <template v-if="!!salaryDetail.slipGaji.pendapatan.remunerasiList === true && !!salaryDetail.slipGaji.pendapatan.remunerasiList.length === true">
        <div
          v-for="(item, index) in salaryDetail.slipGaji.pendapatan.remunerasiList"
          :key="index"
          class="mb-3 grid grid-cols-1 gap-3"
        >
          <div class="col-span-1">
            <div class="mb-3 uppercase font-bold text-lg">
              {{ item.nama }}
            </div>
          </div>
  
          <!-- Gaji pokok -->
          <div
            v-if="!!item.data.gajiPokok === true && !!item.data.gajiPokok.data.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Gaji Pokok
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.gajiPokok.data"
              :key="indexChild"
              class="pl-5 mb-3 grid grid-cols-12 gap-3"
            >
              <div class="col-span-6">
                {{ itemChild.manPowerName }}
              </div>
              <div class="col-span-2 text-center">
                {{ itemChild.hadir }}
              </div>
              <div class="col-span-2 text-right">
                {{ itemChild.index }}
              </div>
              <div class="col-span-2 text-right">
                {{ itemChild.total }}
              </div>
            </div>
          </div>
  
          <!-- Tunjangan -->
          <div
            v-if="!!item.data.tunjangan === true && !!item.data.tunjangan.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Tunjangan
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.tunjangan"
              :key="indexChild"
              class="mb-3 grid grid-cols-12 gap-3"
            >
              <div class="ml-5 col-span-12 font-medium text-lg border-b">
                {{ itemChild.manPowerName }}
              </div>
              <template v-if="!!itemChild.data === true && !!itemChild.data.length === true">
                <div
                  v-for="(itemChildChild, indexChildChild) in itemChild.data"
                  :key="indexChildChild"
                  class="pl-10 mb-3 col-span-12 grid grid-cols-12 gap-3"
                >
                  <div class="col-span-6 pl-3">
                    {{ itemChildChild.nama }}
                  </div>
                  <div class="col-span-2 text-center">
                    {{ itemChildChild.durasi }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.index }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.total }}
                  </div>
                </div>
              </template>
            </div>
          </div>
  
          <!-- Tunjangan Ekstra -->
          <div
            v-if="!!item.data.tunjangan_extra === true && !!item.data.tunjangan_extra.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Tunjangan Extra
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.tunjangan_extra"
              :key="indexChild"
              class="mb-3 grid grid-cols-12 gap-3"
            >
              <div class="ml-5 col-span-12 font-medium text-lg border-b">
                {{ itemChild.manPowerName }}
              </div>
              <template v-if="!!itemChild.data === true && !!itemChild.data.length === true">
                <div
                  v-for="(itemChildChild, indexChildChild) in itemChild.data"
                  :key="indexChildChild"
                  class="pl-10 mb-3 col-span-12 grid grid-cols-12 gap-3"
                >
                  <div class="col-span-6 pl-3">
                    {{ itemChildChild.nama }}
                  </div>
                  <div class="col-span-2 text-center">
                    {{ itemChildChild.durasi }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.index }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.total }}
                  </div>
                </div>
              </template>
            </div>
          </div>
  
          <!-- Tunjangan Site -->
          <div
            v-if="!!item.data.tunjangan_site === true && !!item.data.tunjangan_site.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Tunjangan Site
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.tunjangan_site"
              :key="indexChild"
              class="mb-3 grid grid-cols-12 gap-3"
            >
              <div class="ml-5 col-span-12 font-medium text-lg border-b">
                {{ itemChild.manPowerName }}
              </div>
              <template v-if="!!itemChild.data === true && !!itemChild.data.length === true">
                <div
                  v-for="(itemChildChild, indexChildChild) in itemChild.data"
                  :key="indexChildChild"
                  class="pl-10 mb-3 col-span-12 grid grid-cols-12 gap-3"
                >
                  <div class="col-span-6 pl-3">
                    {{ itemChildChild.nama }}
                  </div>
                  <div class="col-span-2 text-center">
                    {{ itemChildChild.durasi }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.index }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.total }}
                  </div>
                </div>
              </template>
            </div>
          </div>
  
          <!-- Paid leave -->
          <div
            v-if="!!item.data.izin_yang_dibayar === true && !!item.data.izin_yang_dibayar.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Izin Dibayar
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.izin_yang_dibayar"
              :key="indexChild"
              class="mb-3 grid grid-cols-12 gap-3"
            >
              <div class="ml-5 col-span-12 font-medium text-lg border-b">
                {{ itemChild.jenisIzin }}
              </div>
              <template v-if="!!itemChild.data === true && !!itemChild.data.length === true">
                <div
                  v-for="(itemChildChild, indexChildChild) in itemChild.data"
                  :key="indexChildChild"
                  class="pl-10 mb-3 col-span-12 grid grid-cols-12 gap-3"
                >
                  <div class="col-span-6 pl-3">
                    {{ itemChildChild.nama }}
                  </div>
                  <div class="col-span-2 text-center">
                    {{ itemChildChild.durasi }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.index }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.total }}
                  </div>
                </div>
              </template>
            </div>
          </div>
  
          <!-- Lembur hari kerja -->
          <div
            v-if="!!item.data.lembur_hari_kerja === true && !!item.data.lembur_hari_kerja.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Lembur Hari Kerja
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.lembur_hari_kerja"
              :key="indexChild"
              class="mb-3 grid grid-cols-12 gap-3"
            >
              <div class="ml-5 col-span-12 font-medium text-lg border-b">
                {{ itemChild.manPowerName }}
              </div>
              <template v-if="!!itemChild.data === true && !!itemChild.data.length === true">
                <div
                  v-for="(itemChildChild, indexChildChild) in itemChild.data"
                  :key="indexChildChild"
                  class="pl-10 mb-3 col-span-12 grid grid-cols-12 gap-3"
                >
                  <div class="col-span-6 pl-3">
                    {{ itemChildChild.nama }}
                  </div>
                  <div class="col-span-2 text-center">
                    {{ itemChildChild.durasi }} {{ itemChildChild.unit }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.index }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.total }}
                  </div>
                </div>
              </template>
            </div>
          </div>
  
          <!-- Lembur hari libur -->
          <div
            v-if="!!item.data.lembur_hari_libur === true && !!item.data.lembur_hari_libur.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Lembur Hari Libur
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.lembur_hari_libur"
              :key="indexChild"
              class="mb-3 grid grid-cols-12 gap-3"
            >
              <div class="ml-5 col-span-12 font-medium text-lg border-b">
                {{ itemChild.manPowerName }}
              </div>
              <template v-if="!!itemChild.data === true && !!itemChild.data.length === true">
                <div
                  v-for="(itemChildChild, indexChildChild) in itemChild.data"
                  :key="indexChildChild"
                  class="pl-10 mb-3 col-span-12 grid grid-cols-12 gap-3"
                >
                  <div class="col-span-6 pl-3">
                    {{ itemChildChild.nama }}
                  </div>
                  <div class="col-span-2 text-center">
                    {{ itemChildChild.durasi }} {{ itemChildChild.unit }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.index }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.total }}
                  </div>
                </div>
              </template>
            </div>
          </div>
  
          <!-- Penalti kehadiran -->
          <div
            v-if="!!item.data.penalti_kehadiran === true && !!item.data.penalti_kehadiran.length === true"
            class="pl-5 col-span-1"
          >
            <div class="mb-3 uppercase font-semibold text-lg">
              Penalti Kehadiran
            </div>
            <div
              v-for="(itemChild, indexChild) in item.data.penalti_kehadiran"
              :key="indexChild"
              class="mb-3 grid grid-cols-12 gap-3"
            >
              <div class="ml-5 col-span-12 font-medium text-lg border-b">
                {{ itemChild.manPowerName }}
              </div>
              <template v-if="!!itemChild.data === true && !!itemChild.data.length === true">
                <div
                  v-for="(itemChildChild, indexChildChild) in itemChild.data"
                  :key="indexChildChild"
                  class="pl-10 mb-3 col-span-12 grid grid-cols-12 gap-3"
                >
                  <div class="col-span-6 pl-3">
                    {{ itemChildChild.nama }}
                  </div>
                  <div class="col-span-2 text-center">
                    {{ itemChildChild.durasi }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.index }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ itemChildChild.total }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
  
      <!-- Adjustment -->
      <div
        v-if="!!salaryDetail.slipGaji.pendapatan.penyesuaian === true && !!salaryDetail.slipGaji.pendapatan.penyesuaian.data.length === true"
      >
        <div class="mb-3 uppercase font-semibold text-lg">
          Penyesuaian
        </div>
        <div
          v-for="(item, index) in salaryDetail.slipGaji.pendapatan.penyesuaian.data"
          :key="index"
          class="pl-5 mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            {{ item.nama }}
          </div>
          <div class="col-span-6 text-right">
            {{ item.total }}
          </div>
        </div>
      </div>
  
      <!-- Pendapatan Extra -->
      <div
        v-if="(!!salaryDetail.slipGaji.pendapatan.pendapatanExtra === true) && (!!salaryDetail.slipGaji.pendapatan.pendapatanExtra.data.length === true && isSalarySecondPeriod)"
        class="col-span-1"
      >
        <div class="mb-3 uppercase font-semibold text-lg">
          Pendapatan Extra
        </div>
        <div
          v-for="(item, index) in salaryDetail.slipGaji.pendapatan.pendapatanExtra.data"
          :key="index"
          class="pl-5 mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            {{ item.nama }}
          </div>
          <div class="col-span-2 text-center">
            {{ item.durasi }}
          </div>
          <div class="col-span-2 text-right">
            {{ item.index }}
          </div>
          <div class="col-span-2 text-right">
            {{ item.total }}
          </div>
        </div>
      </div>
  
      <!-- Benefit -->
      <div
        v-if="(!!salaryDetail.slipGaji.pendapatan.benefit === true) && (!!salaryDetail.slipGaji.pendapatan.benefit.data.length === true && isSalarySecondPeriod)"
        class="col-span-1"
      >
        <div class="mb-3 uppercase font-semibold text-lg">
          Benefit
        </div>
        <div
          v-for="(item, index) in salaryDetail.slipGaji.pendapatan.benefit.data"
          :key="index"
          class="pl-5 mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            {{ item.nama }}
          </div>
          <div class="col-span-6 text-right">
            {{ item.total }}
          </div>
        </div>
      </div>

      <!-- Pendapatan ke-1 -->
      <div
        v-if="isSalarySecondPeriod"
        class="col-span-1"
      >
        <div class="mb-3 uppercase font-semibold text-lg">
          Pendapatan yang sudah diterima
        </div>
        <div
          class="pl-5 mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            Pendapatan ke-1
          </div>
          <div class="col-span-6 text-right">
            {{ data.gajiKe1.total }}
          </div>
        </div>
      </div>
  
      <!-- THR -->
      <div
        v-if="!!salaryDetail.slipGaji.pendapatan.thr === true"
        class="col-span-1"
      >
        <div class="col-span-6 uppercase font-semibold">
          THR
        </div>
        <div class="col-span-6 justify-self-end font-bold">
          {{ salaryDetail.slipGaji.pendapatan.thr }}
        </div>
      </div>
  
      <!-- Total bruto -->
      <template v-if="!!salaryDetail.slipGaji.pendapatan === true && !!salaryDetail.slipGaji.pendapatan.totalPendapatanBruto === true">
        <div class="mb-5 grid grid-cols-1 gap-3">
          <div class="mb-3 py-3 px-2 grid grid-cols-12 gap-3 bg-blue-100 rounded-lg">
            <div class="col-span-6 uppercase font-semibold">
              Total Penghasilan Bruto
            </div>
            <div class="col-span-6 justify-self-end font-bold">
              {{ salaryDetail.slipGaji.pendapatan.totalPendapatanBruto }}
            </div>
          </div>
        </div>
      </template>
  
      <!-- Deduksi title -->
      <div class="mb-5 p-3 text-xl font-semibold border-b-4 border-blue-600">
        Deduksi
      </div>
  
      <!-- Deduksi -->
      <div
        class="mb-3 grid grid-cols-1 gap-3"
      >
        <div
          v-if="isSalaryFirstPeriod"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            Deposit 5%
          </div>
          <div class="col-span-6 text-right">
            {{ salaryDetail.slipGaji.deduksi.deposit_pph21_5p }}
          </div>
        </div>
        <template v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.pendapatan_sudah_diterima === true) && (!!salaryDetail.slipGaji.deduksi.pendapatan_sudah_diterima.data.length === true && isSalarySecondPeriod)">
          <div class="mb-3 grid grid-cols-12 gap-3">
            <div class="col-span-12 font-medium text-lg border-b">
              Pendapatan Yang Sudah Diterima Sebelum di Hitung Pajak
            </div>
          </div>
          <div
            v-for="(itemChildChild, indexChildChild) in salaryDetail.slipGaji.deduksi.pendapatan_sudah_diterima.data"
            :key="indexChildChild"
            class="pl-5 mb-3 grid grid-cols-12 gap-3"
          >
            <div class="col-span-6 pl-3">
              {{ itemChildChild.nama }}
            </div>
            <div class="col-span-6 text-right">
              {{ itemChildChild.total }}
            </div>
          </div>
        </template>
        <template v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.penyesuaian === true) && (!!salaryDetail.slipGaji.deduksi.penyesuaian.data.length === true)">
          <div class="mb-3 grid grid-cols-12 gap-3">
            <div class="col-span-12 font-medium text-lg border-b">
              Penyesuaian
            </div>
          </div>
          <div
            v-for="(itemChildChild, indexChildChild) in salaryDetail.slipGaji.deduksi.penyesuaian.data"
            :key="indexChildChild"
            class="pl-5 mb-3 grid grid-cols-12 gap-3"
          >
            <div class="col-span-6 pl-3">
              {{ itemChildChild.nama }}
            </div>
            <div class="col-span-6 text-right">
              {{ itemChildChild.total }}
            </div>
          </div>
        </template>
        <template v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.pinjaman)">
          <div class="mb-3 grid grid-cols-12 gap-3">
            <div class="col-span-6">
              Pinjaman Karyawan
            </div>
            <div class="col-span-6 text-right">
              {{ salaryDetail.slipGaji.deduksi.pinjaman }}
            </div>
          </div>
        </template>
        <div
          v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.pph_21) && isSalarySecondPeriod"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            PPH 21
          </div>
          <div class="col-span-6 text-right">
            {{ salaryDetail.slipGaji.deduksi.pph_21 }}
          </div>
        </div>
        <div
          v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.bpjs_kesehatan === true) && isSalarySecondPeriod"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            BPJS Kesehatan 1% yang dibayar karyawan
          </div>
          <div class="col-span-6 text-right">
            {{ salaryDetail.slipGaji.deduksi.bpjs_kesehatan }}
          </div>
        </div>
        <div
          v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.jp === true) && isSalarySecondPeriod"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            JP (1%) yang dibayarkan karyawan
          </div>
          <div class="col-span-6 text-right">
            {{ salaryDetail.slipGaji.deduksi.jp }}
          </div>
        </div>
        <div
          v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.jht === true) && isSalarySecondPeriod"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            JHT (2%) yang dibayarkan karyawan
          </div>
          <div class="col-span-6 text-right">
            {{ salaryDetail.slipGaji.deduksi.jht }}
          </div>
        </div>
        <div
          v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.iuran_kesehatan_dibayar_perusahaan === true) && isSalarySecondPeriod"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            Iuran Kesehatan yang dibayarkan perusahaan
          </div>
          <div class="col-span-6 text-right">
            {{ salaryDetail.slipGaji.deduksi.iuran_kesehatan_dibayar_perusahaan }}
          </div>
        </div>
        <div
          v-if="(!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.iuran_jkk_jkm_dibayar_perusahaan === true) && isSalarySecondPeriod"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            Iuran JKK & JKM yang dibayarkan perusahaan
          </div>
          <div class="col-span-6 text-right">
            {{ salaryDetail.slipGaji.deduksi.iuran_jkk_jkm_dibayar_perusahaan }}
          </div>
        </div>
      </div>
  
      <!-- Total deduksi -->
      <template v-if="!!salaryDetail.slipGaji.deduksi === true && !!salaryDetail.slipGaji.deduksi.total_deduksi === true">
        <div class="mb-5 grid grid-cols-1 gap-3">
          <div class="mb-3 py-3 px-2 grid grid-cols-12 gap-3 bg-blue-100 rounded-lg">
            <div class="col-span-6 uppercase font-semibold">
              Total Deduksi
            </div>
            <div class="col-span-6 justify-self-end font-bold">
              {{ salaryDetail.slipGaji.deduksi.total_deduksi }}
            </div>
          </div>
        </div>
      </template>

      <!-- Pengembalian deposit title -->
      <div class="mb-5 p-3 text-xl font-semibold border-b-4 border-blue-600">
        Pengembalian Deposit
      </div>

      <!-- Deposit list -->
      <template v-if="(!!salaryDetail.slipGaji.deposit === true) && (!!salaryDetail.slipGaji.deposit.length === true && isSalarySecondPeriod)">
        <div
          v-for="(deposit, index) in salaryDetail.slipGaji.deposit"
          :key="index"
          class="mb-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-6">
            {{ deposit.key }}
          </div>
          <div class="col-span-6 text-right">
            {{ deposit.value }}
          </div>
        </div>
      </template>
  
      <!-- Gaji diterima -->
      <div class="grid grid-cols-1 gap-3">
        <div class="col-span-1">
          <div class="my-3 py-3 px-2 grid grid-cols-12 gap-3 bg-blue-800 text-white rounded-lg">
            <div class="col-span-6 uppercase font-bold">
              Gaji Yang Diterima
            </div>
            <div class="col-span-6 justify-self-end font-bold">
              {{ salaryDetail.total }}
            </div>
          </div>
        </div>
      </div> 

      <!-- Kurang bayar -->
      <div
        v-if="salaryDetail.slipGaji.kurangBayar"
        class="grid grid-cols-1 gap-3"
      >
        <div class="col-span-1">
          <div class="my-3 py-3 px-2 grid grid-cols-12 gap-3 bg-blue-800 text-white rounded-lg">
            <div class="col-span-6 uppercase font-bold">
              Kurang Potong PPH/BPJS
            </div>
            <div class="col-span-6 justify-self-end font-bold">
              {{ salaryDetail.slipGaji.kurangBayarNilai }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div 
      v-else-if="isPtrSalary"
      style="min-width: 700px;"
    >
      <template>

        <!-- Slip list -->
        <div
          v-for="(item, index) in salaryDetail.slip"
          :key="index"
          class="mb-5 py-3 px-5 rounded-lg border border-blue-800"
        >
          <div>
            <div class="w-full grid grid-cols-2">
              <div class="detail">
                <div class="detail__key">
                  Periode
                </div>
                <div class="detail__value">
                  : {{ item.periode }}
                </div>
                <div class="detail__key">
                  Jenis Pembayaran
                </div>
                <div class="detail__value">
                  : {{ item.jenisPembayaran }}
                </div>
                <div class="detail__key">
                  Upah Dasar
                </div>
                <div class="detail__value">
                  : {{ item.upahDasar }}
                </div>
                <div class="detail__key">
                  Keterangan
                </div>
                <div class="detail__value">
                  : {{ item.keterangan }}
                </div>
              </div>
              <div class="detail">
                <div class="detail__key">
                  Total
                </div>
                <div class="detail__value">
                  : {{ item.total }}
                </div>
                <div class="detail__key">
                  Deposit 5%
                </div>
                <div class="detail__value">
                  : {{ item.deposit5p }}
                </div>
                <div class="detail__key">
                  Yang Dibayarkan
                </div>
                <div class="detail__value">
                  : {{ item.totalYangDibayarkan }}
                </div>
                <div class="detail__key">
                  Est. Tanggal Bayar +2 hr
                </div>
                <div class="detail__value">
                  : {{ item.tanggalPembayaran }}
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <!-- Gaji diterima -->
        <div class="grid grid-cols-1 gap-3">
          <div class="col-span-1">
            <div class="mb-3 py-3 px-2 grid grid-cols-12 gap-3 bg-blue-800 text-white rounded-lg">
              <div class="col-span-6 uppercase font-bold">
                {{ salaryDetail.title }}
              </div>
              <div class="col-span-6 justify-self-end font-bold">
                {{ salaryDetail.total }}
              </div>
            </div>
          </div>
        </div>    
      </template>
    </div>

    <div 
      v-else-if="isExtraSalary"
      style="min-width: 700px;"
    >
      <template>

        <!-- Slip list -->
        <div
          v-for="(item, index) in salaryDetail.slip"
          :key="index"
          class="mb-5 py-3 px-5 rounded-lg border border-blue-800"
        >
          <div class="mb-2 pb-3 border-b text-xl font-semibold text-green-500">
            {{ item.header.kategori }}
          </div>
          <p class="mb-5">{{ item.header.periode }}</p>
          <p class="mb-5">
            <span class="font-medium">Keterangan: </span>
            {{ item.header.keterangan }}
          </p>

          
          <div>
            <div class="mb-5 p-3 text-xl font-semibold border-b-4 border-blue-600 text-blue-600">
              Pendapatan
            </div>

            <div class="w-full grid grid-cols-2">
              <div class="detail">
                <div class="col-span-1 py-1 text-black font-bold">
                  Extra
                </div>
                <div class="detail__value-extra"></div>
                <div class="detail__key-extra pl-2">
                  Jumlah Hari / Jam
                </div>
                <div class="detail__value-extra">
                  {{ item.header.jumlahKehadiran }}
                </div>
                <div class="detail__key-extra pl-2">
                  Upah Dasar
                </div>
                <div class="detail__value-extra">
                  {{ item.pendapatan.extra.upahDasar }}
                </div>
                <div class="detail__key-extra pl-2">
                  Total Extra
                </div>
                <div class="detail__value-extra">
                  {{ item.pendapatan.extra.total }}
                </div>

                <div v-if="!!item.pendapatan.penyesuaian.data === true && !!item.pendapatan.penyesuaian.data.length === true" class="col-span-1 py-1 text-black font-bold">
                  Penyesuaian (+)
                </div>
                <div v-if="!!item.pendapatan.penyesuaian.data === true && !!item.pendapatan.penyesuaian.data.length === true" class="detail__value">
                </div>
                <template v-if="!!item.pendapatan.penyesuaian.data === true && !!item.pendapatan.penyesuaian.data.length === true">
                  <div
                    class="w-full col-span-2 grid grid-cols-2"
                    v-for="(itemChild, indexChild) in item.pendapatan.penyesuaian.data"
                    :key="indexChild"
                  >
                    <div
                      class="detail__key-extra pl-2"
                    >
                      {{ itemChild.judul }} 
                    </div>
                    <div
                      class="detail__value-extra"
                    >
                      {{ itemChild.nilai }}
                    </div>
                  </div>
                </template>
                <div class="detail__key-extra pl-2">
                  Total
                </div>
                <div class="detail__value-extra">
                  {{ item.pendapatan.penyesuaian.total }}
                </div>
                <div class="col-span-1 py-1 font-bold text-blue-700">
                  Total Pendapatan
                </div>
                <div class="detail__value">
                  {{ item.pendapatan.totalPendapatan }}
                </div>
              </div>
            </div>

            <div class="mb-5 p-3 text-xl font-semibold border-b-4 border-red-600 text-red-600">
              Deduksi
            </div>

            <div class="w-full grid grid-cols-2">
              <div class="detail">

                <div v-if="!!item.deduksi.penyesuaian.data === true && !!item.deduksi.penyesuaian.data.length === true" class="col-span-1 py-1 text-black font-bold">
                  Penyesuaian (-)
                </div>
                <div v-if="!!item.deduksi.penyesuaian.data === true && !!item.deduksi.penyesuaian.data.length === true" class="detail__value">
                </div>
                <template v-if="!!item.deduksi.penyesuaian.data === true && !!item.deduksi.penyesuaian.data.length === true">
                  <div
                    class="w-full col-span-2 grid grid-cols-2"
                    v-for="(itemChild, indexChild) in item.deduksi.penyesuaian.data"
                    :key="indexChild"
                  >
                    <div
                      class="detail__key-extra pl-2"
                    >
                      {{ itemChild.judul }} 
                    </div>
                    <div
                      class="detail__value-extra"
                    >
                      {{ itemChild.nilai }}
                    </div>
                  </div>
                </template>
                <div class="detail__key-extra">
                  Angsuran Pinjaman
                </div>
                <div class="detail__value-extra">
                  {{ item.deduksi.angsuranPinjaman }}
                </div>
                <div class="detail__key-extra">
                  Deposit 5%
                </div>
                <div class="detail__value-extra">
                  {{ item.deduksi.deposit5p }}
                </div>
                <div class="col-span-1 py-1 font-bold text-red-700">
                  Total Deduksi
                </div>
                <div class="detail__value">
                  {{ item.deduksi.totalDeduksi }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div
      v-else-if="!isSalaryFirstPeriod && !isSalarySecondPeriod && !isPtrSalary && !isExtraSalary"
      class="h-96 w-full flex items-center justify-center"
    >
      <span class="font-bold text-3xl">{{ salaryDetail }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SlipSalaryDetail',
  computed: {
    ...mapState('slip', ['data', 'loading']),
    ...mapGetters('slip', ['salaryDetail', 'isSalaryFirstPeriod', 'isSalarySecondPeriod', 'isPtrSalary', 'isExtraSalary'])
  }
}
</script>

<style lang="sass" scoped>
.detail
  @apply col-span-2 md:col-span-1 grid grid-cols-2

  &__key
    @apply col-span-1 py-1 text-gray-500 align-top

    &-medium
      @apply col-span-1 pl-3 py-1 text-left font-medium

  &__value
    @apply col-span-1 pl-3 py-1 text-left font-bold

    &-extra
      @apply col-span-1 pl-3 py-1 text-left font-normal
</style>
