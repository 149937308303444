<template>
  <div class="w-full lg:w-3/4 lg:p-3">
    <div class="h-full w-full lg:px-28 lg:bg-blue-300 lg:rounded-2xl shadow-lg overflow-y-auto overflow-x-hidden">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseMainContainer'
}
</script>