/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    submitLogin({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}auth/login`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000
          }
        })
        .then(res => {
          sessionStorage.setItem('token', res.data.data.token)
          sessionStorage.setItem('userData', JSON.stringify(res.data.data))
          resolve(res)
        })
        .catch(err => reject(err))
      }) 
    }
  },
  getters: {
    userToken: () => sessionStorage.getItem('token'),
    userData: () => JSON.parse(sessionStorage.getItem('userData'))
  }
})