import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import '@/assets/css/index.css'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VToolTip from 'v-tooltip'
import VCalendar from 'v-calendar'
import VueFinalModal from 'vue-final-modal'

Vue.use(VCalendar)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VToolTip)
Vue.use(VueFinalModal(), {
  componentName: 'VueFinalModal'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
