/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    loading: true,
    salaryTitle: ''
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setLoading(state, data) {
      state.loading = data
    },
    setSalaryTitle(state, data) {
      state.salaryTitle = data
    },
    togglePayrollDetail(state, data) {
      switch (data) {
        case 'first-salary':
          state.data.gajiKe1.show = true
          state.data.gajiKe2.show = false            
          state.data.ptr.show = false
          state.data.extra.show = false
          break
        case 'second-salary':
          state.data.gajiKe1.show = false
          state.data.gajiKe2.show = true
          state.data.ptr.show = false
          state.data.extra.show = false
          break
        case 'ptr':
          state.data.gajiKe1.show = false
          state.data.gajiKe2.show = false
          state.data.ptr.show = true
          state.data.extra.show = false
          break
        case 'extra':
          state.data.gajiKe1.show = false
          state.data.gajiKe2.show = false
          state.data.ptr.show = false
          state.data.extra.show = true
          break
        default:
          break
      }
    }
  },
  actions: {
    getSalarySlip({ commit }, { employeeId, year, month }) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        
        axios.get(`${process.env.VUE_APP_PENGGAJIAN_API_URL}sync/slip_gaji_mingguan_v2?id_karyawan=${employeeId}&tahun=${year}&bulan=${month}`)
        .then(res => {
          const data = res.data.data
          const formattedSlipGajiKe1 = {
            ...data.gajiKe1.slipGaji,
            deposit: data.gajiKe1.slipGaji.deposit ? data.gajiKe1.slipGaji.deposit.map(item => ({ key: Object.keys(item)[0], value: Object.values(item)[0] })) : []
          }
          const formattedSlipGajiKe2 = {
            ...data.gajiKe2.slipGaji,
            deposit: data.gajiKe2.slipGaji.deposit ? data.gajiKe2.slipGaji.deposit.map(item => ({ key: Object.keys(item)[0], value: Object.values(item)[0] })) : [],
            kurangBayar: data.gajiKe2.slipGaji.kurangBayar,
            kurangBayarNilai: data.gajiKe2.slipGaji.kurangBayar ? data.gajiKe2.slipGaji.kurangBayarNilai : ''
          }
          data.gajiKe1.slipGaji = formattedSlipGajiKe1
          data.gajiKe2.slipGaji = formattedSlipGajiKe2 
          data.extra.show = false
          data.ptr.show = false

          commit('setData', data)

          if (data.gajiKe1.show) {
            commit('setSalaryTitle', 'Gaji Periode 1')
          } else if (data.gajiKe2.show) {
            commit('setSalaryTitle', 'Gaji Periode 2')
          } else {
            commit('setSalaryTitle', '')
          }
          resolve(res)
        })
        .catch(err => reject(err))
        .finally(() => commit('setLoading', false))
      })
    },
  },
  getters: {
    salaryDetail: state => {
      if (state.data.gajiKe1.show) {
        return state.data.gajiKe1
      } else if (state.data.gajiKe2.show) {
        return state.data.gajiKe2
      } else if (state.data.ptr.show) {
        return state.data.ptr
      } else if (state.data.extra.show) {
        return state.data.extra
      } else {
        return 'Data belum tersedia'
      }
    },
    isSalaryFirstPeriod: state => state.data.gajiKe1.show,
    isSalarySecondPeriod: state => state.data.gajiKe2.show,
    firstPeriodSalary: state => state.data.gajiKe1,
    secondPeriodSalary: state => state.data.gajiKe2,
    ptrSalary: state => state.data.ptr,
    extraSalary: state => state.data.extra,
    isPtrSalary: state => state.data.ptr.show,
    isExtraSalary: state => state.data.extra.show
  }
})