<template>
  <div class="h-full w-full flex">

    <div class="h-screen w-full lg:p-3 hidden lg:block lg:w-1/4">
      <BaseNav/>
    </div>

    <BaseMainContainer>

        <div class="h-auto w-full pt-5 px-5 lg:px-0 pb-10 bg-blue-500 lg:bg-blue-300">

          <!-- Title -->
          <div class="h-auto w-full -ml-5 lg:ml-0 pb-5 flex flex-row bg-blue-500 lg:bg-blue-300">
            <button 
              class="mr-2 px-2 bg-white rounded-r-full lg:hidden" 
              @click="$router.push('/')"
            >
              <svg 
                class="h-6 w-6 text-blue-500" 
                viewBox="0 0 24 24"
              >
                <path 
                  fill="currentColor" 
                  d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                />
              </svg>
            </button>
            <h4 class="text-white text-xl font-bold">Slip Gaji</h4>
          </div>
          <!-- Title -->

        </div>

        <div class="w-full -mt-5 lg:mb-10 p-4 bg-white rounded-t-2xl lg:rounded-b-2xl lg:shadow-lg">

          <SlipSummary/>

          <SlipSalaryTitle :salaryTitle="salaryTitle" />

          <SlipSalaryDetail/>

        </div>
    </BaseMainContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseNav from '@/components/base/BaseNav'
import BaseMainContainer from '@/components/base/BaseMainContainer'
import SlipSummary from '@/components/slip/SlipSummary'
import SlipSalaryDetail from '../components/slip/SlipSalaryDetail.vue'
import SlipSalaryTitle from '../components/slip/SlipSalaryTitle.vue'

export default {
  name: 'SlipSalary',
  components: {
    BaseNav,
    BaseMainContainer,
    SlipSummary,
    SlipSalaryDetail,
    SlipSalaryTitle
  },
  computed: {
    ...mapState('slip', ['salaryTitle'])
  }
}
</script>