<template>
  <div class="h-full w-full flex">
    
    <div class="h-screen w-full lg:p-3 hidden lg:block lg:w-1/4">
      <BaseNav/>
    </div>

    <BaseMainContainer>

      <div class="w-full mb-2 pt-5 pb-7 px-5 flex flex-row bg-blue-500 lg:bg-blue-300">
        <button 
          class="-ml-5 mr-2 px-2 bg-white rounded-r-full lg:hidden" 
          @click="$router.push('/approval')"
        >
          <svg 
            class="h-6 w-6 text-blue-500" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" 
            />
          </svg>
        </button>
        <h4 class="lg:-ml-5 text-white text-xl font-bold">Persetujuan Izin</h4>
      </div>

      <div class="h-auto w-full -mt-5 lg:mb-10 p-4 bg-white rounded-t-xl lg:rounded-b-2xl lg:shadow-lg">

        <div class="mb-5 -mx-4 py-5 z-10 sticky top-0 flex row justify-around bg-white">
          <button 
            class="w-full ml-4 mr-2 p-2 rounded-full font-bold"
            :class="status === 'approval-detail' ? 'bg-blue-300' : 'bg-gray-200'"
            @click="status = 'approval-detail'"
          >
            Detail Pengajuan
          </button>
          <button
            class="w-full mr-4 ml-2 p-2 rounded-full font-bold"
            :class="status === 'approval-history' ? 'bg-blue-300' : 'bg-gray-200'"
            @click="status = 'approval-history'"
          >
            5 pengajuan terakhir
          </button>
        </div>

        <div 
          v-if="!detail" 
          class="h-full w-full flex items-center justify-center"
        >
          <svg 
            class="animate-spin text-blue-600 h-10 w-10 mr-3" 
            viewBox="0 0 24 24" 
          >
            <path 
              class="opacity-75" 
              fill="currentColor" 
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>

        <div v-else>

          <div v-if="status === 'approval-detail'">
            <div 
              v-if="detail.status !== 'waiting_approval'" 
              class="mb-5 p-2 flex items-center rounded-md" 
              :class="detail.status === 'approved' ? 'bg-green-200' : detail.status === 'rejected' ? 'bg-red-200' : ''"
            >
              <div>
                <svg 
                  v-if="detail.status === 'approved'" 
                  style="width:50px;height:100%" 
                  viewBox="0 0 24 24" 
                  class="mr-4"
                >
                  <path 
                    fill="#49ac4c" 
                    d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" 
                  />
                </svg>
                <svg 
                  v-else-if="detail.status === 'rejected'" 
                  style="width:50px;height:100%" 
                  viewBox="0 0 24 24" 
                  class="mr-4"
                >
                  <path 
                    fill="#d32f2f" 
                    d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" 
                  />
                </svg>
              </div>
              <div>
                <p class="font-bold">
                  <span 
                    v-if="detail.status === 'approved'" 
                    class="text-green-600"
                  >
                    Disetujui
                  </span>
                  <span 
                    v-else-if="detail.status === 'rejected'" 
                    class="text-red-600"
                  >
                    Ditolak
                  </span>
                </p>
                <p class="text-gray-600">
                  <span v-if="detail.status === 'approved'">Pengajuan izin telah disetujui pada {{ detail.approval_date }}</span>
                  <span v-else-if="detail.status === 'rejected'">Pengajuan izin telah ditolak pada {{ detail.approval_date }}</span>
                </p>
              </div>
            </div>

            <div>
              <div class="mb-3 text-sm font-bold">
                <p>Form No.</p>
                <p class="text-blue-600">{{ detail.permission_number_formatted }}</p>
              </div>
              <hr class="py-2 hidden lg:block">
              <div class="w-full flex flex-col lg:flex-row">
                <div class="w-full">
                  <div class="mb-3 text-sm font-bold">
                    <p>ID Karyawan</p>
                    <p class="lg:text-lg text-blue-600">{{ detail.employee_id }}</p>
                  </div>
                  <div class="mb-3 text-sm font-bold">
                    <p>Nama</p>
                    <p class="lg:text-lg text-blue-600">{{ detail.name }}</p>
                  </div>
                  <div class="mb-3 text-sm font-bold">
                    <p>Jenis Izin</p>
                    <p class="lg:text-lg text-blue-600">{{ detail.permission_type_name }}</p>
                  </div>
                </div>
                <div class="w-full">
                  <div class="mb-3 text-sm font-bold">
                    <p>Tanggal Izin</p>
                    <p class="lg:text-lg text-blue-600">{{ detail.date_formatted }}</p>
                  </div>
                  <div class="mb-3 text-sm font-bold">
                    <p>Keterangan</p>
                    <p class="lg:text-lg text-blue-600">{{ detail.description }}</p>
                  </div>
                  <div 
                    v-if="detail.status === 'rejected'"
                    class="mb-3 text-sm font-bold"
                  >
                    <p>Alasan Penolakan</p>
                    <p class="lg:text-lg text-blue-600">{{ detail.rejected_description }}</p>
                  </div>
                </div>
              </div>
              <div class="mb-3 text-sm font-bold">
                <p class="mb-2">Lampiran</p>
                <img 
                  :src="detail.attachment" 
                  alt="Foto Izin" 
                  class="w-64 object-cover rounded-xl cursor-pointer"
                  @click="showAttachmentModal = true"
                >
                <VueFinalModal 
                  :classes="['flex', 'items-center', 'justify-center']"
                  :content-class="['h-3/4', 'w-3/4']"
                  v-model="showAttachmentModal" 
                >
                  <div class="h-full p-5 flex flex-col items-center rounded-xl bg-white">
                    <div class="h-full overflow-auto">
                      <img 
                        :src="detail.attachment" 
                        alt="Foto Izin" 
                        ref="modalImage"
                      >
                    </div>
                    <div class="w-full flex items-center justify-end">
                      <button 
                        class="h-10 w-10 m-1 p-2 bg-blue-600 rounded-full"
                        @click="zoomIn"
                      >
                        <svg class="h-6 w-6 text-white" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
                        </svg>
                      </button>
                      <button 
                        class="h-10 w-10 m-1 p-2 bg-blue-600 rounded-full"
                        @click="zoomOut"
                      >
                        <svg class="h-6 w-6 text-white" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </VueFinalModal>
              </div>
            </div>

            <div 
              v-if="detail.status === 'waiting_approval'" 
              class="my-5 p-3 flex items-center bg-red-200 rounded-md border-2 border-red-600"
            >
              <div class="mr-3">
                <div class="h-5 w-5 flex items-center justify-center transform rotate-45 bg-red-500 rounded shadow">
                  <svg 
                    class="h-4 w-4 text-white transform -rotate-45" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      fill="currentColor" 
                      d="M10 3H14V14H10V3M10 21V17H14V21H10Z"
                    />
                  </svg>
                </div>
              </div>
              <p class="text-sm font-bold">Periksa detail pengajuan sebelum melakukan persetujuan.</p>
            </div>

            <div 
              v-if="detail.status === 'waiting_approval'" 
              class="mb-5 -mx-4 py-5 z-10 sticky top-0 flex row justify-around bg-white"
            >
              <button 
                class="w-full ml-4 mr-2 p-2 bg-blue-800 text-white rounded-full font-bold"
                @click="approvePermission(detail.id)"
              >
                Setujui
              </button>
              <button
                @click="showRejectionReasonModal = true"
                class="w-full mr-4 ml-2 p-2 bg-gray-200 rounded-full font-bold">
                Tolak
              </button>
          
            </div>
          </div>

          <div v-else-if="status === 'approval-history'">
            <div 
              v-for="approvalHistory in detail.permission_history_list" 
              :key="approvalHistory.id"
            >
              <div class="mb-5 p-3 bg-white cursor-pointer rounded-lg shadow-xl border-2 border-gray-100">
                <div class="flex items-start">
                  
                  <div class="w-full">
                    <transition 
                      name="slide-fade" 
                      mode="out-in"
                    >
                      <div 
                        v-if="historyListDetailId !== approvalHistory.id" 
                        key="first"
                        class="w-full py-2 px-3 flex items-center justify-between border-2 border-gray-200 rounded-md"
                      >
                        <div>
                          <p class="mb-2 uppercase text-xs md:text-sm">{{ approvalHistory.label }}</p>
                          <p class="text-xs md:text-sm">{{ approvalHistory.date }}</p>
                        </div>
                        <p 
                          class="mt-auto text-xs md:text-sm" 
                          :class="approvalHistory.status === 'approved' ? 'text-green-600' : approvalHistory.status === 'rejected' ? 'text-red-600' : ''"
                        >
                          {{ formattedPermissionStatus(approvalHistory.status) }}
                        </p>
                      </div>
                      <div 
                        v-else-if="historyListDetailId === approvalHistory.id" 
                        key="second"
                        class="w-full py-2 px-3 items-center justify-between border-2 border-gray-200 rounded-md"
                      >
                        <div 
                          class="mb-5 p-2 flex items-center rounded-md" 
                          :class="approvalHistory.status === 'approved' ? 'bg-green-200' : approvalHistory.status === 'rejected' ? 'bg-red-200' : ''"
                        >
                          <div>
                            <svg 
                              v-if="approvalHistory.status === 'approved'" 
                              style="width:50px;height:100%" 
                              viewBox="0 0 24 24" 
                              class="mr-4"
                            >
                              <path 
                                fill="#49ac4c" 
                                d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" 
                              />
                            </svg>
                            <svg
                              v-else-if="approvalHistory.status === 'rejected'" 
                              style="width:50px;height:100%" 
                              viewBox="0 0 24 24" 
                              class="mr-4"
                            >
                              <path 
                                fill="#d32f2f" 
                                d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" 
                              />
                            </svg>
                          </div>
                          <div>
                            <p class="font-bold">
                              <span 
                                v-if="approvalHistory.status === 'approved'" 
                                class="text-green-600"
                              >
                                Disetujui
                              </span>
                              <span 
                                v-else-if="approvalHistory.status === 'rejected'" 
                                class="text-red-600"
                              >
                                Ditolak
                              </span>
                            </p>
                            <p class="text-gray-600">
                              <span v-if="approvalHistory.status === 'approved'">Pengajuan izin telah disetujui pada {{ approvalHistory.approval_date }}</span>
                              <span v-else-if="approvalHistory.status === 'rejected'">Pengajuan izin telah ditolak pada {{ approvalHistory.approval_date }}</span>
                            </p>
                          </div>
                        </div>

                        <div>
                          <div class="mb-3 text-sm font-bold">
                            <p>Form No.</p>
                            <p class="text-blue-600">{{ approvalHistory.permission_number_formatted }}</p>
                          </div>
                          <div class="mb-3 text-sm font-bold">
                            <p>ID Karyawan</p>
                            <p class="text-blue-600">{{ approvalHistory.employee_id }}</p>
                          </div>
                          <div class="mb-3 text-sm font-bold">
                            <p>Nama</p>
                            <p class="text-blue-600">{{ approvalHistory.employee_name }}</p>
                          </div>
                          <div class="mb-3 text-sm font-bold">
                            <p>Jenis Izin</p>
                            <p class="text-blue-600">{{ approvalHistory.label }}</p>
                          </div>
                          <div class="mb-3 text-sm font-bold">
                            <p>Tanggal Izin</p>
                            <p class="text-blue-600">{{ approvalHistory.date }}</p>
                          </div>
                          <div class="mb-3 text-sm font-bold">
                            <p>Keterangan</p>
                            <p class="text-blue-600">{{ approvalHistory.description }}</p>
                          </div>
                          <div class="mb-3 text-sm font-bold">
                            <p>Lampiran</p>
                            <img 
                              :src="approvalHistory.attachment" 
                              alt="Foto Izin" 
                              class="w-64 object-cover rounded-xl"
                            >
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>

                  <div class="flex flex-col items-center">
                    <div>
                      <svg 
                        v-if="historyListDetailId !== approvalHistory.id" 
                        @click="detailToggle(approvalHistory.id)" 
                        class="h-8 w-8 ml-2 mb-1 text-blue-600 cursor-pointer" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          fill="currentColor" 
                          d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M6,10L12,16L18,10L16.6,8.6L12,13.2L7.4,8.6L6,10Z" 
                        />
                      </svg>
                      <svg 
                        v-else-if="historyListDetailId === approvalHistory.id" 
                        @click="detailToggle(approvalHistory.id)" 
                        class="h-8 w-8 ml-2 mb-1 text-blue-600 cursor-pointer" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          fill="currentColor" 
                          d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M7.4,15.4L12,10.8L16.6,15.4L18,14L12,8L6,14L7.4,15.4Z"
                        />
                      </svg>
                    </div>
                  </div>

                </div>
              </div>
            </div>        
          </div>

        </div>

      </div>
      <VueFinalModal 
        :classes="['flex', 'items-center', 'justify-center']"
        :content-class="['w-96']"
        v-model="showRejectionReasonModal" 
      >
        <div class="h-full p-5 rounded-xl bg-white">
          <div>
            <label 
              for="rejection-reason"
              class="mb-1 font-bold"
            >
              Alasan Penolakan
            </label>
            <textarea 
              name="rejection-reason"
              id="rejection-reason"
              rows="3" 
              class="w-full my-2 p-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
              v-model="rejectedDescription"
            ></textarea>
            <!-- <p class="text-red-500 font-semibold">Alasan Penolakan wajib diisi.</p> -->
            <button 
              class="mt-5 py-2 px-4 bg-blue-800 text-white rounded-full font-bold"
              @click="rejectPermission(detail.id)"
            >
              Tolak
            </button>
          </div>
        </div>
      </VueFinalModal>    

    </BaseMainContainer>

  </div>  
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseNav from '@/components/base/BaseNav'
import BaseMainContainer from '@/components/base/BaseMainContainer'

export default {
  name: 'ApprovalDetail',
  components: {
    BaseNav,
    BaseMainContainer
  },
  computed: {
    ...mapGetters('auth', ['userToken']),
    ...mapGetters('approval', ['detail'])
  },
  data() {
    return {
      status: 'approval-detail',
      filterModal: false,
      historyListDetailId: '',
      approveLoadingState: false,
      rejectLoadingState: false,
      showAttachmentModal: false,
      showRejectionReasonModal: false,
      rejectedDescription: '',
      submitted: false

    }
  },
  validations: {
    rejectedDescription: { required }
  },
  created() {
    this.getApprovalDetail({
      id: this.$route.params.id,
      token: this.userToken
    })
  },
  methods: {
    ...mapActions('approval', ['getApproval', 'getApprovalDetail', 'patchApprovalApprove', 'patchApprovalReject']),
    formattedPermissionStatus(status) {
      return status === 'approved' ? 'Disetujui' : 
      status === 'rejected' ? 'Ditolak' : 
      status === 'waiting_approval' ? 'Menunggu Persetujuan' : ''
    },
    approvePermission(id) {
      this.approveLoadingState = true

      this.patchApprovalApprove({
        id,
        token: this.userToken
      })
      .then(res => {
        this.approveLoadingState = false
        this.$swal({
          position: 'center',
          icon: 'success',
          html: `<span><b>${res.data.message}</b></span>`,
          showConfirmButton: false,
          timer: 3000,
          heightAuto: false
        })
        this.getApproval({
          status: 'waiting_approval',
          token: this.userToken
        })
        this.$router.push('/approval')
      })
      .catch(err => {
        this.approveLoadingState = false
        if (err.response) {
          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>${err.response.data.message}</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })
        } else if (!err.response) {
          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>Terjadi kesalahan saat request!</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })
        }
      })
    },
    rejectPermission(id) {
      this.rejectLoadingState = true
      this.submitted = true
      this.$v.$touch()

      if (this.$v.$invalid) {

        this.rejectLoadingState = false
        return 

      } else {
        
        this.patchApprovalReject({
          id,
          rejectedDescription: this.rejectedDescription,
          token: this.userToken
        })
        .then(res => {
          this.rejectLoadingState = false
          console.log(res)
          console.log(res.data.message)
          this.$swal({
            position: 'center',
            icon: 'success',
            html: `<span><b>${res.data.message}</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })
          this.getApproval({
            status: 'waiting_approval',
            token: this.userToken
          })      
          this.$router.push('/approval')
        })
        .catch(err => {
          this.rejectLoadingState = false
          if (err.response) {
            this.$swal({
              position: 'center',
              icon: 'error',
              html: `<span><b>${err.response.data.message}</b></span>`,
              showConfirmButton: false,
              timer: 3000,
              heightAuto: false
            })
          } else if (!err.response) {
            this.$swal({
              position: 'center',
              icon: 'error',
              html: `<span><b>Terjadi kesalahan saat request!</b></span>`,
              showConfirmButton: false,
              timer: 3000,
              heightAuto: false
            })
          }
        })

      }

    },
    detailToggle(id) {
      if (!this.historyListDetailId) {
        this.historyListDetailId = id
      } else if (this.historyListDetailId) {
        if (this.historyListDetailId == id) {
          this.historyListDetailId = ''
        } else if (this.historyListDetailId != id) {
          this.historyListDetailId = id
        }
      }
    },
    zoomIn() {
      let image = this.$refs.modalImage
      let currentWidth = image.clientWidth
      image.style.width = (currentWidth + 100) + 'px'
    },
    zoomOut() {
      let image = this.$refs.modalImage
      let currentWidth = image.clientWidth
      image.style.width = (currentWidth - 100) + 'px'
    }
  }
}
</script>

<style lang="sass" scoped>
.slide-fade-enter-active 
  transition: all 0.2s ease

.slide-fade-leave-active 
  transition: all 0.2s ease

.slide-fade-enter, .slide-fade-leave-to
  transform: translateY(10px)
  opacity: 0
</style>
