<template>
  <div class="h-full w-full flex flex-col items-center justify-center">
    <div class="mb-10 flex flex-col items-center justify-center">
      <p class="text-6xl font-bold text-red-500">404</p>
      <p class="text-lg font-bold">Halaman Tidak Ditemukan</p>
    </div>
    <div>
      <BaseButton 
        type="primary" 
        text="Kembali ke halaman awal"
        class="px-3"
        @button-click="$router.push('/')"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'PageNotFound',
  components: {
    BaseButton
  }
}
</script>
