<template>
  <div class="font-bold text-3xl flex items-center justify-center my-5">
    <BaseSkeleton v-if="loading" class="h-10 w-64" />
    <template v-else>{{ salaryTitle }}</template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseSkeleton from '../base/BaseSkeleton.vue'

export default {
  name: 'SlipSalaryTitle',
  props: {
    salaryTitle: String
  },
  computed: {
    ...mapState('slip', ['loading'])
  },
  components: { BaseSkeleton }
}
</script>
