<template>
  <div class="h-full w-full flex items-center justify-center">

    <svg 
      class="fixed bottom-0 hidden md:block" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 1440 320"
    >
      <path 
        fill="#0099ff" 
        fill-opacity="1" 
        d="M0,32L1440,0L1440,320L0,320Z"
      />
    </svg>

    <div class="w-full z-10 flex items-center justify-center">
      <div class="w-full md:w-1/2 lg:w-96 px-3 py-5 flex flex-col items-center justify-center md:rounded-lg md:shadow-md bg-white md:border-2 md:border-gray-100">
        <img 
          src="@/assets/img/datangg-logo-plain.png" 
          alt="Datangg logo" 
          class="w-3/4 mt-5 mb-10"
        >
        <div class="login-form w-full px-6">
      
          <form 
            @submit.prevent="login" 
            class="flex flex-col"
          >
            
            <div class="mb-4">
              <label class="mb-2">ID Karyawan</label>
              <div 
                class="h-12 w-full mb-2 px-2 flex flex-row items-center border-solid border border-gray-400  focus-within:border-blue-500 rounded-xl" 
                :class="submitted && $v.employeeIdGroup.$error ? 'border-red-500' : ''"
              >
                <svg 
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24" 
                  class="text-blue-500"
                >
                  <path 
                    fill="currentColor" 
                    d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" 
                  />
                </svg>
                <div class="w-full flex flex-row items-center justify-start">
                  <input 
                    type="text" 
                    class="w-3/12 px-2 bg-white" 
                    v-model="employeeIdFirst" 
                    disabled
                  >
                  <span class="w-1/12 text-center font-bold">-</span>
                  <input 
                    type="number" 
                    class="w-3/12 px-2 bg-white text-center" 
                    autofocus
                    v-model="employeeIdSecond" 
                    @input="secondEmployeeIdInput"
                  >
                  <span class="w-1/12 text-center font-bold">-</span>
                  <input 
                    type="number" 
                    class="w-4/12 px-2 bg-white text-center" 
                    ref="thirdInput" 
                    v-model="employeeIdThird"
                    @input="thirdEmployeeIdInput"
                  >
                </div>
              </div>
              <span 
                v-if="submitted && $v.employeeIdGroup.$error" 
                class="text-red-500 font-semibold"
              >
                ID Karyawan wajib diisi
              </span>
            </div>
            
            <div class="mb-20">
              <label class="mb-2">Kata Sandi</label>
              <div 
                class="h-12 w-full mb-2 px-2 flex flex-row items-center justify-between border-solid border border-gray-400 focus-within:border-blue-500 rounded-xl" 
                :class="submitted && !$v.password.required ? 'border-red-500' : ''"
              >
                <svg 
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24" 
                  class="text-blue-500"
                >
                  <path 
                    fill="currentColor" 
                    d="M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10A2,2 0 0,1 6,8H15V6A3,3 0 0,0 12,3A3,3 0 0,0 9,6H7A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,17A2,2 0 0,1 10,15A2,2 0 0,1 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17Z" 
                  />
                </svg>
                <input 
                  placeholder="Kata Sandi" 
                  class="w-full px-2" 
                  :type="inputTypePass === true ? 'password' : 'text'" 
                  v-model="password"
                  ref="passInput"
                >
                <svg 
                  v-if="!inputTypePass" 
                  @click="inputTypePass = !inputTypePass" 
                  viewBox="0 0 24 24" 
                  class="h-6 w-6 text-blue-500 focus:text-blue-800"
                >
                  <path 
                    fill="currentColor" 
                    d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" 
                  />
                </svg>
                <svg 
                  v-else 
                  @click="inputTypePass = !inputTypePass"
                  viewBox="0 0 24 24" 
                  class="h-6 w-6 text-blue-500 focus:text-blue-800"
                >
                  <path 
                    fill="currentColor" 
                    d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" 
                  />
                </svg>
              </div>
              <span 
                v-if="submitted && !$v.password.required" 
                class="text-red-500 font-semibold"
              >
                Kata Sandi wajib diisi
              </span>
            </div>

            <BaseButton 
              type="success" 
              text="Masuk" 
              :loading="loading"
              @button-click="login"
            >
              <template #icon>
                <svg 
                  class="h-6 w-6"
                  viewBox="0 0 24 24"
                >
                  <path 
                    fill="currentColor" 
                    d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z" 
                  />
                </svg>
              </template>
            </BaseButton>

          </form>
          
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseButton from '@/components/base/BaseButton'
export default {
  name: 'Login',
  components: {
    BaseButton
  },
  data() {
    return {
      employeeIdFirst: 'BPP',
      employeeIdSecond: '',
      employeeIdThird: '',
      password: '',
      loading: false,
      submitted: false,
      inputTypePass: true
    }
  },
  validations: {
    employeeIdFirst: { required },
    employeeIdSecond: { required },
    employeeIdThird: { required },
    employeeIdGroup: ['employeeIdFirst', 'employeeIdSecond', 'employeeIdThird'],
    password: { required }
  },
  methods: {
    ...mapActions('auth', ['submitLogin']),
    secondEmployeeIdInput() {
      if (this.employeeIdSecond.length === 4) this.$refs.thirdInput.focus()
    },
    thirdEmployeeIdInput() {
      if (this.employeeIdThird.length === 5) this.$refs.passInput.focus()
    },
    login() {
      this.loading = true
      this.submitted = true
      this.$v.$touch()

      if (this.$v.$invalid) {

        this.loading = false
        return

      } else {

        this.submitLogin({
          employee_id: `${this.employeeIdFirst}-${this.employeeIdSecond}-${this.employeeIdThird}`,
          password: this.password
        })
        .then(() => {
          this.loading = false
          this.$router.push('/')
        })
        .catch(err => {
          this.loading = false

          if (err.response) {

            this.$swal({
              position: 'center',
              icon: 'error',
              html: `<span><b>${err.response.data.message}</b></span>`,
              showConfirmButton: false,
              timer: 3000
            })

          } else if (!err.response) {

            this.$swal({
              position: 'center',
              icon: 'error',
              html: `<span><b>Terjadi kesalahan saat request!</b></span>`,
              showConfirmButton: false,
              timer: 3000
            })

          }
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-buttons 
  -webkit-appearance: none
  margin: 0

input[type=number] 
  -moz-appearance: textfield
</style>