<template>
  <div class="h-full w-full">

    <div class="w-full pt-5 pb-7 px-5 flex flex-row bg-blue-500">
      <button 
        class="-ml-5 mr-2 px-2 bg-white rounded-r-full" 
        @click="$router.push('/')"
      >
        <svg 
          class="h-6 w-6 text-blue-500" 
          viewBox="0 0 24 24"
        >
          <path 
            fill="currentColor" 
            d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" 
          />
        </svg>
      </button>
      <h4 class="text-white text-xl font-bold">Detail Cuti</h4>
    </div>

    <div class="h-full w-full -mt-5 p-4 bg-white rounded-t-xl">
      
      <div class="mb-6 flex flex-row items-end header-container">
        <div class="mr-1 flex flex-col items-center header-left">
          <p class="mb-2 font-bold">Ringkasan Cuti</p>
          <div class="w-full p-2 bg-blue-200 rounded-md shadow-md text-center">
            <p>Hutang Cuti Tahun Lalu</p>
            <p class="font-bold">2</p>
            <hr class="my-2 border-gray-400">
            <p>Saldo Cuti Tahun Ini</p>
            <p class="font-bold">10</p>
          </div>
        </div>
        <div class="h-32 ml-1 bg-green-200 rounded-md shadow-md header-right"></div>
      </div>

      <div class="w-full">
        <table class="w-full">
          <thead>
            <tr class="font-bold">
              <td class="p-1">Tanggal</td>
              <td class="p-1">Keterangan</td>
              <td class="p-1 text-center">Sisa Saldo</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-1">02 Januari 2020</td>
              <td class="p-1">Cuti Tahunan</td>
              <td class="p-1 text-center">9</td>
            </tr>
            <tr>
              <td class="p-1">20 Januari 2020</td>
              <td class="p-1">Alpha</td>
              <td class="p-1 text-center">8</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'AnnualLeaveDetail'
}
</script>

<style lang="sass" scoped>
.header-container

  .header-left
    width: 100%

  .header-right
    display: none

@media (min-width: 350px)
  .header-container

    .header-left
      width: 50%
    
    .header-right
      width: 50%
      display: block
</style>
