<template>
  <div class="h-full w-full flex">

    <div class="h-screen w-full lg:p-3 hidden lg:block lg:w-1/4">
      <BaseNav/>
    </div>

    <BaseMainContainer>

      <div class="h-auto w-full mb-2 pt-5 pb-7 px-5 flex flex-row bg-blue-500 lg:bg-blue-300">
        <button 
          class="-ml-5 mr-2 px-2 bg-white rounded-r-full lg:hidden" 
          @click="$router.push('/permission')"
        >
          <svg 
            class="h-6 w-6 text-blue-500"
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
        </button>
        <h4 class="lg:-ml-5 text-white text-xl font-bold">Permohonan Izin</h4>
      </div>

      <div class="h-auto w-full -mt-5 lg:mb-10 p-4 bg-white rounded-t-xl lg:rounded-b-2xl lg:shadow-lg">

        <form @submit.prevent="requestPermission">

          <div class="mb-6 flex flex-col">
            <label class="mb-2 font-bold">Jenis Izin</label>
            <select 
              class="h-12 px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
              v-model="selectedPermission" 
              @change="setTotalDays"
            >
              <option 
                value="" 
                disabled 
                selected
              >
                Pilih Jenis Izin
              </option>
              <option 
                v-for="permissionType in permissionTypeList" 
                :key="permissionType.code" 
                :value="permissionType"
              >
                {{ permissionType.name }}
              </option>
            </select>
            <p 
              v-if="selectedPermission.code === 'cuti_tahunan'" 
              class="ml-auto"
            >
              Saldo saat ini: {{ annualLeave.saldo }}
            </p>
          </div>

          <div class="mb-6 flex flex-row">

            <div class="w-full flex flex-col">

              <div class="mb-2 flex flex-row items-center justify-between">
                <label class="font-bold">Pilih Tanggal</label>
                <svg 
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    fill="currentColor" 
                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" 
                  />
                </svg>
              </div>

              <v-date-picker 
                v-if="selectedPermission.code === 'cuti_tahunan' || selectedPermission.code === 'cuti_tidak_berbayar' || selectedPermission.code === 'sakit'"
                v-model="dateRange"
                mode="date" 
                is-range 
                class="w-full"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="h-12 w-full px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500"
                    :value="`${inputValue.start} - ${inputValue.end}`"
                    v-on="inputEvents.start"
                  />
                </template>
              </v-date-picker>

              <v-date-picker 
                v-else-if="selectedPermission.code === 'lupa_absen_datang' || selectedPermission.code === 'lupa_absen_pulang'"
                v-model="singleDate"
                mode="date"
                class="w-full"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="h-12 w-full px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </v-date-picker>

              <input
                v-else
                class="h-12 w-full px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500"
                disabled
                :value="new Date().toLocaleDateString('en-CA')"
              />

            </div>

            <!-- <div class="w-1/2 mr-4 flex flex-col">
              <div class="mb-2 flex flex-row items-center justify-between">
                <label class="font-bold">Pilih Tanggal</label>
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
                </svg>
              </div>
              <input 
                class="h-12 w-full px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
                :type="(selectedPermission.code === 'izin_datang_terlambat' || selectedPermission.code === 'izin_pulang_cepat') ? 'datetime-local' : 'date'" 
                v-model="start_date" 
                @input="setEndDate(start_date, selectedDays)"
              >
            </div>

            <div class="w-1/2 ml-4 flex flex-col">
              <label class="mb-2 font-bold">Jumlah Hari</label>
              <select 
                class="h-12 px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
                :disabled="totalDays.length === 0" 
                v-model="selectedDays"
                @change="setEndDate(start_date, selectedDays)">
                <option 
                  v-for="day in totalDays" 
                  :key="day.id" 
                  :value="day">
                  {{ day }}
                </option>
              </select>
            </div> -->

          </div>

          <div class="mb-6 flex flex-col">
            <label class="mb-2 font-bold">Atasan Saya</label>
            <select 
              class="h-12 px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
              v-model="selectedApprovalUser"
            >
              <option 
                value="" 
                disabled 
                selected
              >
                Pilih Atasan
              </option>
              <option 
                v-for="user in approvalList" 
                :key="user.id" 
                :value="user">
                {{ user.fullname }}
              </option>
            </select>
          </div>

          <div class="mb-6 flex flex-col">
            <label class="mb-2 font-bold">Deskripsi</label>
            <textarea 
              cols="30" 
              rows="5" 
              class="px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
              v-model="description"
            ></textarea>
          </div>

          <div 
            v-if="selectedPermission.code === 'sakit' || selectedPermission.code === 'cuti_tahunan' || selectedPermission.code === 'cuti_tidak_berbayar'" 
            class="mb-6"
          >
            <label 
              v-if="!files" 
              for="attachment" 
              class="mb-2 flex flex-col"
            >
              <div class="py-2 flex flex-row items-center justify-center rounded-full shadow-md focus:shadow-2xl bg-green-custom">
                <svg 
                  viewBox="0 0 24 24" 
                  class="h-6 w-6 text-white"
                >
                  <path 
                    fill="currentColor" 
                    d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
                  />
                </svg>
                <span class="text-white">Tambah Lampiran</span>
              </div>
              <input 
                type="file" 
                name="attachment" 
                id="attachment" 
                accept="image/*" 
                class="hidden" 
                @change="fileSelected"
              >
            </label>
            <div v-if="files">
              <p class="mb-2 flex flex-row items-center">
                <svg 
                  viewBox="0 0 24 24"
                  class="h-6 w-6"
                >
                  <path 
                    fill="currentColor" 
                    d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" 
                  />
                </svg>
                <span class="font-bold">Lampiran</span>
              </p>
              <div class="w-full p-4 flex flex-row items-center justify-between bg-gray-300 border border-black rounded-md">
                <span>{{ files[0].name }}</span>
                <span>
                  <svg 
                    @click="files = ''" 
                    viewBox="0 0 24 24" 
                    class="mr-4 h-8 w-8"
                  >
                    <path 
                      fill="currentColor" 
                      d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <span 
              v-else 
              class="text-gray-500"
            >
              Belum ada file dipilih
            </span>
          </div>

          <BaseButton 
            type="primary" 
            class="w-full lg:w-auto lg:ml-auto lg:px-3" 
            text="Ajukan" 
            :loading='loading' 
            @button-click="requestPermission"
          />

        </form>

      </div>
    
    </BaseMainContainer>


  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseNav from '@/components/base/BaseNav'
import BaseMainContainer from '@/components/base/BaseMainContainer'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'PermissionAdd',
  components: {
    BaseNav,
    BaseMainContainer,
    BaseButton
  },
  computed: {
    ...mapState('permission', ['approvalList', 'permissionTypeList', 'editableForm', 'editableFormState', 'annualLeave']),
    ...mapGetters('auth', ['userToken'])
  },
  data() {
    return {
      selectedPermission: '',
      selectedApprovalUser: '',
      id: '',
      singleDate: new Date(),
      dateRange: {
        start: new Date(),
        end: new Date(),
      },
      start_date: '',
      end_date: '',
      selectedDays: '',
      totalDays: '',
      description: '',
      files: '',
      loading: false
    }
  },
  methods: {
    ...mapMutations('permission', ['setFormEdit', 'setFormState', 'setDetail']),
    ...mapActions('permission', ['createPermission', 'updatePermission', 'deletePermission']),
    setTotalDays() {
      this.totalDays = []
      this.end_date = ''
      this.selectedDays = ''
      for (let i = 1; i <= this.selectedPermission.jumlah_hari; i++) {
        this.totalDays.push(i)
      }
    },
    setEndDate(date, range) {
      if (this.selectedDays) {
        let startDate = new Date(date)
        if (this.selectedPermission.code === 'izin_datang_terlambat' || this.selectedPermission.code === 'izin_pulang_cepat') {
          this.start_date = new Date(startDate.getTime() - startDate.getTimezoneOffset()*60*1000).toISOString().substr(0,19).replace('T', ' ')
          this.end_date = this.start_date
        } else {
          this.end_date = new Date(startDate.setDate(startDate.getDate() + ( range - 1 )))
          this.end_date = this.end_date.toLocaleDateString('en-CA')
        }
      }       
    },
    fileSelected(e) {
      this.files = e.target.files
    },
    requestPermission() {
      this.loading = true
      let data = new FormData()
      let startDate
      let endDate

      if (this.selectedPermission.code === 'cuti_tahunan' || this.selectedPermission.code === 'cuti_tidak_berbayar' || this.selectedPermission.code === 'sakit') {
        startDate = this.dateRange.start.toLocaleDateString('en-CA')
        endDate = this.dateRange.end.toLocaleDateString('en-CA')
      } else if (this.selectedPermission.code === 'lupa_absen_datang' || this.selectedPermission.code === 'lupa_absen_pulang') {
        startDate = this.singleDate.toLocaleDateString('en-CA')
        endDate = this.singleDate.toLocaleDateString('en-CA')
      }

      data.append('id', this.id)
      data.append('type', this.selectedPermission.type)
      data.append('code', this.selectedPermission.code)
      data.append('image', this.files[0])
      data.append('start_date', startDate)
      data.append('end_date', endDate)
      data.append('description', this.description)
      data.append('approved_user_hash_id', this.selectedApprovalUser.id)
      data.append('cuti_khusus_id', this.selectedPermission.id)

      this.createPermission({
        data: data,
        token: this.userToken
      })
      .then(res => {

        this.loading = false
        this.$swal({
          position: 'center',
          icon: 'success',
          html: `<span><b>${res.data.message}</b></span>`,
          showConfirmButton: false,
          timer: 3000,
          heightAuto: false
        })
        this.$router.push('/permission')

      })
      .catch(err => {

        this.loading = false
        if (err.response) {

          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>${err.response.data.message}</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })

        } else if (!err.response) {

          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>Terjadi kesalahan saat request!</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })

        }

      })
    }
  }
}
</script>

<style lang="sass" scoped>
.bg-green-custom
  background-color: #4cb050
</style>