<template>
  <div class="h-full w-full lg:bg-blue-300 lg:rounded-2xl shadow-lg">

    <div class="pt-3 px-3 pb-14 bg-blue-300 lg:rounded-2xl">

      <div>
        <img src="@/assets/img/datangg-logo-plain.png" alt="Logo Datangg" width="100px">
      </div>

      <div class="p-3 bg-cover" :style="{ backgroundImage: `url(${require('@/assets/img/map-indo.png')})`}">
        <div class="p-2 bg-opacity-70 flex items-center justify-between bg-blue-500 rounded-lg shadow-md">
          <div class="text-white font-semibold">
            <p>{{ userData.name }}</p>
            <p class="text-sm">{{ userData.employee_id }}</p>
          </div>
          <img 
            :src="userData.photo_url" 
            alt="Foto Profil"
            class="object-cover h-20 w-20 rounded-md">
        </div>
      </div>

      <p class="my-2 text-center font-bold text-blue-600">#BerdikariMembangunNKRI</p>

    </div>

    <div class="-mt-12 lg:mx-3 p-3 bg-white rounded-t-2xl lg:rounded-2xl lg:shadow-md">
      
      <div class="mt-5 lg:mb-5 grid grid-cols-3 gap-3">

        <router-link
          v-if="showMenu('permission')" 
          to="/permission" 
          class="p-1 flex flex-col items-center cursor-pointer rounded-md"
          :class="{'shadow-md': permissionRoutesCheck()}">
          <div class="h-16 w-16 mb-2 flex items-center justify-center border rounded-full shadow-md" :class="permissionRoutesCheck() ? 'border-transparent bg-blue-500' : 'border-blue-500 bg-white'">
            <svg class="h-10 w-10" :class="permissionRoutesCheck() ? 'text-white' : 'text-blue-500'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M15 6H3v2h12V6z" fill="currentColor"/><path d="M15 10H3v2h12v-2z" fill="currentColor"/><path d="M3 14h8v2H3v-2z" fill="currentColor"/><path d="M11.99 15.025l1.415-1.414l2.121 2.121l4.243-4.242l1.414 1.414l-5.657 5.657l-3.535-3.536z" fill="currentColor"/></g></svg>
          </div>
          <p class="text-sm">Izin</p>
        </router-link>

        <router-link 
          v-if="showMenu('permission_approval')"
          to="/approval" 
          class="p-1 flex flex-col items-center cursor-pointer rounded-md"
          :class="{'shadow-md': approvalRoutesCheck()}">
          <div class="h-16 w-16 mb-2 flex items-center justify-center border rounded-full shadow-md" :class="approvalRoutesCheck() ? 'border-transparent bg-green-500' : 'border-green-500 bg-white'">
            <svg class="h-10 w-10" :class="approvalRoutesCheck() ? 'text-white' : 'text-green-500'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M23 12l-2.44-2.78l.34-3.68l-3.61-.82l-1.89-3.18L12 3L8.6 1.54L6.71 4.72l-3.61.81l.34 3.68L1 12l2.44 2.78l-.34 3.69l3.61.82l1.89 3.18L12 21l3.4 1.46l1.89-3.18l3.61-.82l-.34-3.68L23 12m-13 5l-4-4l1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" fill="currentColor"/></svg>
          </div>
          <p class="text-sm">Persetujuan</p>
        </router-link>

        <router-link 
          to="/slip/salary"
          class="p-1 flex flex-col items-center cursor-pointer rounded-md"
          :class="{'shadow-md': approvalRoutesCheck()}">
          <div class="h-16 w-16 mb-2 flex items-center justify-center border rounded-full shadow-md" :class="approvalRoutesCheck() ? 'border-transparent bg-purple-500' : 'border-purple-500 bg-white'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" :class="approvalRoutesCheck() ? 'text-white' : 'text-purple-500'" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M6 2a2 2 0 0 0-2 2v7h1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v11h-3v1h4a3 3 0 0 0 3-3v-2h-3V4a2 2 0 0 0-2-2H6Zm11 11a2 2 0 0 1-2 2v-3h2v1ZM7.5 5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4ZM7 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM8.5 12a1.5 1.5 0 0 1 1.5 1.5v3A1.5 1.5 0 0 1 8.5 18h-6A1.5 1.5 0 0 1 1 16.5v-3A1.5 1.5 0 0 1 2.5 12h6Zm.5 4.5v-1A1.5 1.5 0 0 0 7.5 17h1a.5.5 0 0 1 .5-.5Zm0-3a.5.5 0 0 1-.5-.5h-1A1.5 1.5 0 0 0 9 14.5v-1ZM2.5 13a.5.5 0 0 1-.5.5v1A1.5 1.5 0 0 0 3.5 13h-1ZM2 16.5a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 2 15.5v1Zm3.5-3a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3Z"/></svg>
          </div>
          <p class="text-sm">Slip</p>
        </router-link>

      </div>
    
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseNav',
  methods: {
    permissionRoutesCheck() {
      return this.$route.path === '/permission' 
      || this.$route.path === `/permission-detail/${this.$route.params.id}` 
      || this.$route.path === '/new-permission'
    },
    approvalRoutesCheck() {
      return this.$route.path === '/approval' || this.$route.path === '/approval-detail'
    },
    showMenu(type) {
      return this.userData.home_menu.find(menu => menu.type === type).enable
    }
  },
  computed: {
    ...mapGetters('auth', ['userData'])
  }
}
</script>

<style>

</style>