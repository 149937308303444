<template>
  <div class="h-full w-full flex">

    <div class="h-screen w-full lg:p-3 hidden lg:block lg:w-1/4">
      <BaseNav/>
    </div>

    <BaseMainContainer>

      <div class="w-full mb-2 pt-5 pb-7 px-5 flex flex-row bg-blue-500 lg:bg-blue-300">
        <button
          class="-ml-5 mr-2 px-2 bg-white rounded-r-full lg:hidden" 
          @click="$router.push('/')"
        >
          <svg 
            class="h-6 w-6 text-blue-500" 
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor" 
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
        </button>
        <h4 class="lg:-ml-5 text-white text-xl font-bold">Persetujuan Izin</h4>
      </div>

      <div class="h-auto w-full -mt-5 lg:mb-10 p-4 bg-white rounded-t-xl lg:rounded-b-2xl lg:shadow-lg">

        <div class="mb-5 -mx-4 py-5 z-10 sticky top-0 flex row justify-around bg-white">
          <button 
            class="w-full ml-4 mr-2 p-2 rounded-full font-bold"
            :class="status === 'waiting_approval' ? 'bg-blue-800 text-white' : 'bg-gray-200'"
            @click="toggleListStatus('waiting_approval')"
          >
            Menunggu Persetujuan
          </button>
          <button
            class="w-full mr-4 ml-2 p-2 rounded-full font-bold"
            :class="status === 'done' ? 'bg-blue-800 text-white' : 'bg-gray-200'"
            @click="toggleListStatus('done')"
          >
            Selesai
          </button>
        </div>

        <div v-if="status === 'waiting_approval'">
          <div class="mb-5 flex items-center">
            <div class="mr-3">
              <div class="h-5 w-5 flex items-center justify-center transform rotate-45 bg-red-500 rounded shadow">
                <svg 
                  class="h-4 w-4 text-white transform -rotate-45" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    fill="currentColor" 
                    d="M10 3H14V14H10V3M10 21V17H14V21H10Z"
                  />
                </svg>
              </div>
            </div>
            <p class="text-sm font-bold">Periksa detail pengajuan sebelum melakukan persetujuan.</p>
          </div>

          <div 
            v-if="!data" 
            class="my-20 flex items-center justify-center"
          >
            <svg 
              class="animate-spin text-blue-600 h-10 w-10 mr-3" 
              viewBox="0 0 24 24" 
            >
              <path 
                class="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>

          <div v-else-if="!data.length">
            <p class="my-20 text-center">Belum ada permohonan ijin yang diajukan</p>
          </div>

          <div v-else-if="data.length">
            <div 
              v-for="waitingApproval in data" 
              :key="waitingApproval.id"
            >
              <div 
                class="mb-5 p-3 flex row items-center justify-between bg-white hover:bg-blue-50 cursor-pointer border border-blue-600 rounded-lg shadow-xl"
                @click="$router.push(`/approval-detail/${waitingApproval.id}`)" 
              >
                <div>
                  <p class="text-xs md:text-sm font-semibold">
                    <span class="uppercase">{{ waitingApproval.employee_id }}</span>
                    <span> - </span>
                    <span>{{ waitingApproval.employee_name }}</span>
                  </p>
                  <p>
                    <span class="text-xs md:text-sm text-blue-800 font-bold">{{ waitingApproval.date }}</span>
                  </p>
                </div>
                <p class="uppercase text-xs md:text-sm font-semibold text-right">{{ waitingApproval.label }}</p>
              </div>
            </div>
          </div>

        </div>

        <div v-else-if="status === 'done'">
          <div class="mb-5 flex items-center">
            <div class="mr-3">
              <div class="h-5 w-5 flex items-center justify-center bg-blue-800 rounded-full shadow">
                <svg 
                  class="h-3 w-3 text-white" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlns:xlink="http://www.w3.org/1999/xlink" 
                  aria-hidden="true" 
                  focusable="false" 
                  width="1em" 
                  height="1em" 
                  style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" 
                  preserveAspectRatio="xMidYMid meet" 
                  viewBox="0 0 20 20"
                >
                  <path 
                    d="M12.432 0c1.34 0 2.01.912 2.01 1.957c0 1.305-1.164 2.512-2.679 2.512c-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141c-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467c1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271c.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" 
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
            <p class="text-sm font-bold">Riwayat daftar pengajuan</p>
          </div>

          <div 
            v-if="!data" 
            class="my-20 flex items-center justify-center"
          >
            <svg 
              class="animate-spin text-blue-600 h-10 w-10 mr-3" 
              viewBox="0 0 24 24" 
            >
              <path 
                class="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>

          <div v-else-if="!data.length">
            <p class="my-20 text-center">Belum ada permohonan ijin yang telah disetujui / ditolak</p>
          </div>

          <div v-else-if="data.length">
            <div 
              v-for="approvalHistory in data" 
              :key="approvalHistory.id"
            >
              <div 
                class="mb-5 p-3 flex row items-center justify-between bg-white hover:bg-blue-50 cursor-pointer border border-blue-600 rounded-lg shadow-xl"
                @click="$router.push(`/approval-detail/${approvalHistory.id}`)" 
              >
                <div>
                  <p class="text-xs md:text-sm font-semibold">
                    <span class="uppercase">{{ approvalHistory.employee_id }}</span>
                    <span> - </span>
                    <span>{{ approvalHistory.employee_name }}</span>
                  </p>
                  <p>
                    <span class="text-xs md:text-sm text-blue-800 font-bold">{{ approvalHistory.date }}</span>
                  </p>
                </div>
                <p class="uppercase text-xs md:text-sm font-semibold text-right">{{ approvalHistory.label }}</p>
              </div>
            </div>
          </div>

        </div>

        <div class="w-full -mx-4 hidden z-20 fixed bottom-0">
          <button 
            class="mx-auto mb-10 py-1 px-3 flex items-center rounded shadow bg-blue-800 text-white"
            @click="filterModal = true" 
          >
            <span>
              <svg 
                class="h-4 w-4 mr-3" 
                viewBox="0 0 24 24"
              >
                <path 
                  fill="currentColor" 
                  d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z" 
                />
              </svg>
            </span>
            Filter
          </button>
          <div 
            v-if="filterModal" 
            class="h-screen w-screen"
          >
            <div 
              @click="filterModal = false" 
              class="h-1/2 w-full bg-gray-600 opacity-70"
            ></div>
            <div class="h-1/2 w-full p-6 flex flex-col justify-between bg-white">
              <div>
                <p class="mb-5 font-bold">Filter</p>
                <div>
                  <p class="mb-5 font-bold">Urutkan Berdasarkan</p>
                  <div class="mb-10 flex flex-wrap">
                    <div 
                      v-for="type in filterType" 
                      :key="type.id"
                    >
                      <div 
                        class="mr-4 mb-4 py-1 px-3 border rounded shadow-md text-sm"
                        :class="type === selectedFilter ? 'bg-blue-300 border-blue-500' : 'bg-white border-blue-600'"
                        @click="selectedFilter = type"
                      >
                        {{ type }}
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
              <div class="flex justify-center">
                <button class="py-1 px-3 rounded shadow-lg bg-blue-800 text-white font-bold">Tampilkan hasil filter</button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </BaseMainContainer>

  </div>  
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseNav from '@/components/base/BaseNav'
import BaseMainContainer from '@/components/base/BaseMainContainer'

export default {
  name: 'Approval',
  components: {
    BaseNav,
    BaseMainContainer
  },
  computed: {
    ...mapState('approval', ['data']),
    ...mapGetters('auth', ['userToken'])
  },
  data() {
    return {
      status: 'waiting_approval',
      filterModal: false,
      filterType: [
        'Tanggal Terbaru',
        'Tanggal Terlama',
        'Tipe',
        'Nama A-Z',
        'Nama Z-A',
        'Status'
      ],
      selectedFilter: 'Tanggal Terbaru'
    }
  },
  methods: {
    ...mapMutations('approval', ['setData']),
    ...mapActions('approval', ['getApproval']),
    toggleListStatus(status) {
      this.status = status
      this.setData(null)
      this.getApproval({
        status: this.status,
        token: this.userToken
      })
    },
    selectFilter(filterType) {
      this.selectFilter = filterType
    }
  },
  created() {
    this.getApproval({
      status: 'waiting_approval',
      token: this.userToken
    })
  }
}
</script>
