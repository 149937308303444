<template>
  <div class="h-full w-full flex">

    <div class="h-screen w-full lg:p-3 hidden lg:block lg:w-1/4">
      <BaseNav/>
    </div>

    <BaseMainContainer>

      <div class="h-auto w-full mb-2 pt-5 pb-7 px-5 flex flex-row bg-blue-500 lg:bg-blue-300">
        <button 
          class="-ml-5 mr-2 px-2 bg-white rounded-r-full lg:hidden" 
          @click="$router.push('/permission')"
        >
          <svg 
            class="h-6 w-6 text-blue-500" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
        </button>
        <h4 class="lg:-ml-5 text-white text-xl font-bold">{{ editableForm ? 'Permohonan Izin' : 'Detail Izin' }}</h4>
      </div>

      <div class="h-auto w-full -mt-5 lg:mb-10 p-4 bg-white rounded-t-xl lg:rounded-b-2xl lg:shadow-lg">

        <div 
          v-if="!detail" 
          class="h-full w-full flex items-center justify-center"
        >
          <svg 
            class="animate-spin text-blue-600 h-10 w-10 mr-3" 
            viewBox="0 0 24 24" 
          >
            <path 
              class="opacity-75" 
              fill="currentColor" 
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>

        <div v-else>

          <div v-if="editableForm">
            <form @submit.prevent="requestPermission">

              <div class="mb-6 flex flex-col">
                <label class="mb-2 font-bold">Jenis Izin</label>
                <select 
                  class="h-12 px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
                  v-model="detail.selectedPermission"
                >
                  <option 
                    value="" 
                    disabled 
                    selected
                  >
                    Pilih Jenis Izin
                  </option>
                  <option 
                    v-for="permissionType in permissionTypeList" 
                    :key="permissionType.code" 
                    :value="permissionType"
                  >
                    {{ permissionType.name }}
                  </option>
                </select>
                <p 
                  v-if="detail.selectedPermission.code === 'cuti_tahunan'" 
                  class="ml-auto"
                >
                  Saldo saat ini: {{ annualLeave.saldo }}
                </p>
              </div>

              <div class="mb-6 flex flex-row">

                <div class="w-full flex flex-col">

                  <div class="mb-2 flex flex-row items-center justify-between">
                    <label class="font-bold">Pilih Tanggal</label>
                    <svg 
                      style="width:24px;height:24px" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        fill="currentColor" 
                        d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" 
                      />
                    </svg>
                  </div>

                  <v-date-picker 
                    v-if="detail.selectedPermission.code === 'cuti_tahunan' || detail.selectedPermission.code === 'cuti_tidak_berbayar' || detail.selectedPermission.code === 'sakit'"
                    v-model="dateRange"
                    mode="date" 
                    is-range 
                    class="w-full"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="h-12 w-full px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500"
                        :value="`${inputValue.start} - ${inputValue.end}`"
                        v-on="inputEvents.start"
                      />
                    </template>
                  </v-date-picker>

                  <v-date-picker 
                    v-else-if="detail.selectedPermission.code === 'lupa_absen_datang' || detail.selectedPermission.code === 'lupa_absen_pulang'"
                    v-model="singleDate"
                    mode="date"
                    class="w-full"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="h-12 w-full px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>

                  <input
                    v-else
                    class="h-12 w-full px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500"
                    disabled
                    :value="new Date().toLocaleDateString('en-CA')"
                  />

                </div>

              </div>

              <div class="mb-6 flex flex-col">
                <label class="mb-2 font-bold">Atasan Saya</label>
                <select 
                  class="h-12 px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
                  v-model="detail.selectedApprovalUser">
                  <option 
                    value="" 
                    disabled 
                    selected
                  >
                    Pilih Atasan
                  </option>
                  <option 
                    v-for="user in approvalList" 
                    :key="user.id" 
                    :value="user">
                    {{ user.fullname }}
                  </option>
                </select>
              </div>

              <div class="mb-6 flex flex-col">
                <label class="mb-2 font-bold">Deskripsi</label>
                <textarea 
                  cols="30" 
                  rows="5" 
                  class="px-2 bg-white border border-gray-500 rounded-lg focus:border-blue-500" 
                  v-model="detail.description"
                ></textarea>
              </div>

              <div 
                v-if="detail.selectedPermission.code === 'sakit' || detail.selectedPermission.code === 'cuti_tahunan' || detail.selectedPermission.code === 'cuti_tidak_berbayar'"
                class="mb-6"
              >
                <div v-if="!files">
                  <label class="mb-2 font-bold">Lampiran</label>
                  <img 
                    class="mb-2 object-cover rounded-xl"          
                    alt="Lampiran Izin"
                    :src="detail.attachment"
                  > 
                </div>
                <label 
                  v-if="!files" 
                  for="attachment" 
                  class="mb-2 flex flex-col"
                >
                  <div class="py-2 flex flex-row items-center justify-center rounded-full shadow-md focus:shadow-2xl bg-green-custom">
                    <svg 
                      viewBox="0 0 24 24" 
                      class="h-6 w-6 text-white"
                    >
                      <path 
                        fill="currentColor" 
                        d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
                      />
                    </svg>
                    <span class="text-white">Ubah Lampiran</span>
                  </div>
                  <input 
                    type="file" 
                    name="attachment" 
                    id="attachment" 
                    accept="image/*" 
                    class="hidden" 
                    @change="fileSelected"
                  >
                </label>
                <div v-if="files">
                  <p class="mb-2 flex flex-row items-center">
                    <svg 
                      viewBox="0 0 24 24"
                      class="h-6 w-6"
                    >
                      <path 
                        fill="currentColor" 
                        d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" 
                      />
                    </svg>
                    <span class="font-bold">Lampiran</span>
                  </p>
                  <div class="w-full p-4 flex flex-row items-center justify-between bg-gray-300 border border-black rounded-md">
                    <span>{{ files[0].name }}</span>
                    <span>
                      <svg 
                        @click="files = ''" 
                        viewBox="0 0 24 24" 
                        class="mr-4 h-8 w-8"
                      >
                        <path 
                          fill="currentColor" 
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" 
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              
              <BaseButton 
                type="primary" 
                class="w-full lg:w-auto lg:ml-auto lg:px-3" 
                text="Perbarui" 
                :loading='loading' 
                @button-click="requestPermission"
              />
            </form>
          </div>

          <div v-else>
            <div
              class="mb-5 p-2 flex items-center rounded-md" 
              :class="detail.status === 'approved' ? 'bg-green-200' : detail.status === 'waiting_approval' ? 'bg-yellow-200' : detail.status === 'rejected' ? 'bg-red-200' : ''"
            >
              <div>
                <svg 
                  v-if="detail.status === 'approved'" 
                  style="width:50px;height:100%" 
                  viewBox="0 0 24 24" 
                  class="mr-4"
                >
                  <path 
                    fill="#49ac4c" 
                    d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" 
                  />
                </svg>
                <svg 
                  v-else-if="detail.status === 'waiting_approval'" 
                  style="width:50px;height:100%"
                  viewBox="0 0 24 24" 
                  class="mr-4"
                >
                  <path 
                    fill="#f6a824" 
                    d="M 11 8 L 13 8 L 13 15 L 11 15 L 11 8 Z M 13 18 L 13 19 L 11 19 L 11 17 L 13 17 Z M 1 21 H 23 L 12 2" 
                  />
                </svg>
                <svg 
                  v-else-if="detail.status === 'rejected'" 
                  style="width:50px;height:100%" 
                  viewBox="0 0 24 24" 
                  class="mr-4"
                >
                  <path 
                    fill="#d32f2f" 
                    d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" 
                  />
                </svg>
              </div>
              <div>
                <p class="font-bold">
                  <span 
                    v-if="detail.status === 'approved'" 
                    class="text-green-600"
                  >
                    Disetujui
                  </span>
                  <span 
                    v-else-if="detail.status === 'waiting_approval'" 
                    class="text-yellow-600"
                  >
                    Menunggu Persetujuan
                  </span>
                  <span 
                    v-else-if="detail.status === 'rejected'" 
                    class="text-red-600"
                  >
                    Ditolak
                  </span>
                </p>
                <p class="text-gray-600">
                  <span v-if="detail.status === 'approved'">Permohonan izin telah disetujui pada {{ detail.approval_date }}</span>
                  <span v-else-if="detail.status === 'waiting_approval'">Permohonan izin menunggu persetujuan</span>
                  <span v-else-if="detail.status === 'rejected'">Permohonan izin telah ditolak pada {{ detail.approval_date }}</span>
                </p>
              </div>
            </div>

            <div class="mb-4">
              <p class="font-semibold">Jenis Izin</p>
              <p>{{ detail.permission_type_name }}</p>
            </div>

            <div class="mb-4">
              <p class="font-semibold">Tanggal Izin</p>
              <p>{{ detail.date_formatted }} ({{ detail.total_days }} hari)</p>
            </div>

            <div class="mb-4">
              <p class="font-semibold">Atasan Saya</p>
              <p>{{ detail.approval_user_name }}</p>
            </div>

            <div class="mb-4">
              <p class="font-semibold">Deskripsi</p>
              <p>{{ detail.description }}</p>
            </div>

            <div 
              v-if="detail.status === 'rejected'"
              class="mb-4"
            >
              <p class="font-semibold">Alasan Penolakan</p>
              <p>{{ detail.rejected_description }}</p>
            </div>

            <div v-if="detail.permission_type_code === 'sakit' || detail.permission_type_code === 'cuti_tahunan' || detail.permission_type_code === 'cuti_tidak_berbayar'" class="mb-16">
              <p class="font-semibold">Lampiran</p>
              <img 
                alt="Lampiran Izin" 
                class="object-cover rounded-xl"          
                :src="detail.attachment"
              >
            </div>


            <div 
              v-if="detail.status === 'waiting_approval'" 
              class="w-full fixed lg:static px-5 lg:px-0 pt-2 pb-4 bg-white bottom-0 left-0 right-0 flex flex-row items-center lg:justify-end"
            >
              <!-- <v-popover
                offset="7" 
                placement="top-end" 
                boundariesElement="body" 
                popoverClass="bg-white rounded-md shadow-md"> -->
                <BaseButton 
                  type="danger" 
                  class="mx-1"
                  :loading="loading"
                  @button-click="removePermission(detail.id)"
                >
                  <template #icon>
                    <svg 
                      viewBox="0 0 24 24" 
                      class="h-6 w-6 text-white ml-4"
                    >
                      <path 
                        fill="currentColor" 
                        d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" 
                      />
                    </svg>
                  </template>
                </BaseButton>
                <!-- <template #popover>
                  <p class="mb-5 mt-2 font-bold text-center">Hapus izin?</p>
                  <div class="flex flex-row">
                    <button class="mb-2 ml-4 mr-1 p-2 text-red-500" v-close-popover @click="removePermission(detail.id)">Hapus</button>
                    <button class="mb-2 mr-4 ml-1 p-2 text-gray-700" v-close-popover>Batal</button>
                  </div>
                </template>
              </v-popover> -->
              <BaseButton 
                type="primary" 
                text="Edit" 
                class="w-full lg:w-auto lg:px-5 mx-1"
                @button-click="updatePermission"
              > 
                <template #icon>
                  <svg 
                    viewBox="0 0 24 24" 
                    class="h-6 w-6 text-white"
                  >
                    <path 
                      fill="currentColor" 
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" 
                    />
                  </svg>
                </template>
              </BaseButton>
            </div>

          </div>

        </div>

      </div>
    
    </BaseMainContainer>


  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import BaseNav from '@/components/base/BaseNav'
import BaseMainContainer from '@/components/base/BaseMainContainer'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'PermissionDetail',
  components: {
    BaseNav,
    BaseMainContainer,
    BaseButton
  },
  computed: {
    ...mapState('permission', ['approvalList', 'permissionTypeList', 'annualLeave']),
    ...mapGetters('auth', ['userToken'])
  },
  data() {
    return {
      files: '',
      loading: false,
      detail: '',
      singleDate: new Date(),
      dateRange: {
        start: new Date(),
        end: new Date()
      },
      editableForm: false
    }
  },
  methods: {
    ...mapActions('permission', ['createPermission', 'deletePermission', 'getPermissionDetail']),
    fileSelected(e) {
      this.files = e.target.files
    },
    requestPermission() {
      this.loading = true
      let data = new FormData()
      let startDate
      let endDate

      if (this.detail.selectedPermission.code === 'cuti_tahunan' || this.detail.selectedPermission.code === 'cuti_tidak_berbayar' || this.detail.selectedPermission.code === 'sakit') {
        startDate = this.dateRange.start.toLocaleDateString('en-CA')
        endDate = this.dateRange.end.toLocaleDateString('en-CA')
      } else if (this.detail.selectedPermission.code === 'lupa_absen_datang' || this.detail.selectedPermission.code === 'lupa_absen_pulang') {
        startDate = this.singleDate.toLocaleDateString('en-CA')
        endDate = this.singleDate.toLocaleDateString('en-CA')
      }

      data.append('id', this.detail.id)
      data.append('type', this.detail.selectedPermission.type)
      data.append('code', this.detail.selectedPermission.code)
      data.append('image', this.files[0])
      data.append('start_date', startDate)
      data.append('end_date', endDate)
      data.append('description', this.detail.description)
      data.append('approved_user_hash_id', this.detail.selectedApprovalUser.id)
      data.append('cuti_khusus_id', this.detail.selectedPermission.id)
      this.createPermission({
        data: data,
        token: this.userToken
      })
      .then(res => {
        this.loading = false
        this.$swal({
          position: 'center',
          icon: 'success',
          html: `<span><b>${res.data.message}</b></span>`,
          showConfirmButton: false,
          timer: 3000,
          heightAuto: false
        })
        this.$router.push('/permission')
      })
      .catch(err => {
        this.loading = false
        if (err.response) {
          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>${err.response.data.message}</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })
        } else if (!err.response) {
          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>Terjadi kesalahan saat request!</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })
        }
      })
    },
    removePermission(id) {
      this.loading = true
      this.deletePermission({
        id: id,
        token: this.userToken
      })
      .then(res => {
        this.loading = false
        this.$swal({
          position: 'center',
          icon: 'success',
          html: `<span><b>${res.data.message}</b></span>`,
          showConfirmButton: false,
          timer: 3000,
          heightAuto: false
        })
        this.$router.push('/permission')
      })
      .catch(err => {
        this.loading = false
        if (err.response) {
          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>${err.response.data.message}</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })
        } else if (!err.response) {
          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>Terjadi kesalahan saat request!</b></span>`,
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false
          })
        }
      })
    },
    updatePermission() {
      let selectedPermission = this.permissionTypeList.find(permission => permission.code === this.detail.permission_type_code)
      let totalDays = []
      for (let i = 1; i <= selectedPermission.jumlah_hari; i++) {
        totalDays.push(i)
      }
      let preparedData = {
        id: this.detail.id,
        selectedPermission: selectedPermission,
        attachment: this.detail.attachment,
        start_date: this.detail.start_date,
        end_date: this.detail.end_date,
        description: this.detail.description,
        selectedApprovalUser: this.approvalList.find(user => user.id === this.detail.approval_user_id),
        selectedDays: this.detail.total_days,
        totalDays: totalDays
      }
      this.detail = preparedData
      this.editableForm = true
    },
    permissionDetail() {
      this.getPermissionDetail({
        id: this.$route.params.id,
        token: this.userToken
      })
      .then(res => {
        let startDay = res.data.data.start_date.split('-')[2]
        if (startDay < 10) startDay = startDay[1]
        let startMonth = res.data.data.start_date.split('-')[1]
        startMonth < 10 ? startMonth = startMonth[1] - 1 : startMonth = startMonth - 1
        let startYear = res.data.data.start_date.split('-')[0]

        let endDay = res.data.data.end_date.split('-')[2]
        if (endDay < 10) endDay = endDay[1]
        let endMonth = res.data.data.end_date.split('-')[1]
        endMonth < 10 ? endMonth = endMonth[1] - 1 : endMonth = endMonth - 1
        let endYear = res.data.data.end_date.split('-')[0]

        this.detail = res.data.data
        this.singleDate = new Date(startYear, startMonth, startDay)
        this.dateRange = {
          start: new Date(startYear, startMonth, startDay),
          end: new Date(endYear, endMonth, endDay)
        }
      })
    }
  },
  created() {
    this.permissionDetail()
  }
}
</script>

<style lang="sass" scoped>
.bg-green-custom
  background-color: #4cb050
</style>