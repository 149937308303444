<template>
  <div class="h-full w-full flex">

    <div class="h-screen w-full lg:p-3 hidden lg:block lg:w-1/4">
      <BaseNav/>
    </div>

    <BaseMainContainer>

        <div class="h-auto w-full pt-5 px-5 lg:px-0 pb-10 bg-blue-500 lg:bg-blue-300">

          <!-- Title -->
          <div class="h-auto w-full -ml-5 lg:ml-0 pb-5 flex flex-row bg-blue-500 lg:bg-blue-300">
            <button 
              class="mr-2 px-2 bg-white rounded-r-full lg:hidden" 
              @click="$router.push('/')"
            >
              <svg 
                class="h-6 w-6 text-blue-500" 
                viewBox="0 0 24 24"
              >
                <path 
                  fill="currentColor" 
                  d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                />
              </svg>
            </button>
            <h4 class="text-white text-xl font-bold">Permohonan Izin</h4>
          </div>
          <!-- Title -->

          <div class="h-full w-full flex flex-col items-center justify-centerd lg:shadow-lg">
            <div class="h-full w-full py-2 px-4 flex flex-col items-center justify-around bg-white rounded-t-md">
              <div class="mb-2 text-center">
                <p class="font-semibold">Cuti Tahunan</p>
                <p class="font-semibold">{{ new Date().getFullYear() }}</p>
              </div>
              <div class="w-full flex flex-row items-center justify-around">
                <div class="flex flex-col items-center justify-center">
                  <p>Hutang</p>
                  <div>
                    <svg 
                      v-if="!annualLeave" 
                      class="animate-spin text-blue-600 h-5 w-5 mr-3" 
                      viewBox="0 0 24 24" 
                    >
                      <path 
                        class="opacity-75" 
                        fill="currentColor" 
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <p 
                      v-else 
                      class="text-blue-500"
                    >
                      {{ annualLeave.hutang }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <p>Saldo</p>
                  <div>
                    <svg 
                      v-if="!annualLeave" 
                      class="animate-spin text-blue-600 h-5 w-5 mr-3" 
                      viewBox="0 0 24 24"
                    >
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <p 
                      v-else 
                      class="text-blue-500"
                    >
                      {{ annualLeave.saldo }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <p>Terpakai</p>
                  <div>
                    <svg 
                      v-if="!annualLeave" 
                      class="animate-spin text-blue-600 h-5 w-5 mr-3" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        class="opacity-75" 
                        fill="currentColor" 
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <p 
                      v-else 
                      class="text-blue-500"
                    >
                      {{ annualLeave.terpakai }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button class="w-full py-2 flex flex-row items-center justify-center bg-blue-700 rounded-b-md text-white cursor-pointer">Detail Izin</button>
          </div>

        </div>

        <div class="h-auto w-full -mt-5 lg:mb-10 p-4 bg-white rounded-t-2xl lg:rounded-b-2xl lg:shadow-lg">

          <div class="carousel-width overflow-x-auto mb-4">
            <div class="w-auto mr-3 flex flex-row flex-nowrap overflow-x-auto">
              <button 
                v-for="status in permissionStatus" 
                :key="status.id" 
                class="w-auto h-10 py-1 px-2 mr-2 whitespace-nowrap bg-gray-300 text-black rounded-full"
                :class="selectedPermissionStatus.value === status.value ? 'bg-blue-500 text-white' : ''"
                @click="filterData(status)"
              > 
                {{ status.label }}
              </button>
            </div>   
          </div>

          <div class="h-full w-full mt-2 flex flex-col items-center justify-start overflow-y-auto">

            <div 
              v-if="!filteredPermissionList" 
              class="my-auto"
            >
              <svg 
                class="animate-spin text-blue-600 h-10 w-10 mr-3" 
                viewBox="0 0 24 24"
              >
                <path 
                  class="opacity-75" 
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>

            <p 
              v-else-if="filteredPermissionList.length === 0" 
              class="my-20"
            >
              Belum ada permohonan izin yang diajukan
            </p>

            <div 
              v-else-if="filteredPermissionList.length !== 0" 
              class="w-full"
            >
              <div 
                v-for="permission in filteredPermissionList" 
                :key="permission.id" 
                class="h-24 w-full mb-4 px-4 flex flex-row items-center border border-solid border-gray-200 rounded-3xl shadow-lg cursor-pointer" 
                @click="$router.push(`/permission-detail/${permission.id}`)"
              >
                <svg 
                  v-if="permission.status === 'Approved'" 
                  viewBox="0 0 24 24" 
                  class="h-20 w-20 mr-4"
                >
                  <path 
                    fill="#49ac4c" 
                    d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" 
                  />
                </svg>
                <svg 
                  v-else-if="permission.status === 'Waiting Approval'" 
                  viewBox="0 0 24 24" 
                  class="h-20 w-20 mr-4"
                >
                  <path 
                    fill="#f6a824" 
                    d="M 11 8 L 13 8 L 13 15 L 11 15 L 11 8 Z M 13 18 L 13 19 L 11 19 L 11 17 L 13 17 Z M 1 21 H 23 L 12 2" 
                  />
                </svg>
                <svg 
                  v-else-if="permission.status === 'Rejected'" 
                  viewBox="0 0 24 24" 
                  class="h-20 w-20 mr-4"
                >
                  <path 
                    fill="#d32f2f" 
                    d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" 
                  />
                </svg>
                <div class="w-full">
                  <p class="font-bold text-lg">{{ permission.date_formatted }}</p>
                  <p>{{ permission.permission_type_name }}</p>
                </div>
              </div>
            </div>

          </div>

        </div>

        <button 
          class="fixed bottom-4 right-2 py-3 px-2 flex flex-row-reverse items-center rounded-full shadow-lg lg:right-5 lg:bottom-6 text-white bg-blue-700"
          @click="$router.push('/new-permission')" 
        >
          <svg 
            style="width:24px;height:24px" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" 
            />
          </svg>
          <span class="mx-2">Ajukan Izin</span>
        </button>

    </BaseMainContainer>


  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import BaseNav from '@/components/base/BaseNav'
import BaseMainContainer from '@/components/base/BaseMainContainer'

export default {
  name: 'Permission',
  components: {
    BaseNav,
    BaseMainContainer
  },
  computed: {
    ...mapState('permission', ['data', 'annualLeave', 'filteredData']),
    ...mapGetters('auth', ['userToken'])
  },
  data() {
    return {
      permissionStatus: [
        {
          label: 'Semua',
          value: 'all'
        },
        {
          label: 'Menunggu Persetujuan',
          value: 'Waiting Approval'
        },
        {
          label: 'Disetujui',
          value: 'Approved'
        },
        {
          label: 'Ditolak',
          value: 'Rejected'
        }
      ],
      selectedPermissionStatus: {
        label: 'Semua',
        value: 'all'
      },
      filteredPermissionList: null
    }
  },
  methods: {
    ...mapMutations('permission', ['filterPermissionList']),
    ...mapActions('permission', ['getPermission']),
    filterData(value) {
      this.selectedPermissionStatus = value
      this.filteredPermissionList = null
        if (value.value !== 'all') {
          this.filterPermissionList(value.value)
          this.filteredPermissionList = this.filteredData
        } else {
          this.filteredPermissionList = this.data
        }
    },
    permissionList() {
      this.getPermission(this.userToken)
      .then(res => this.filteredPermissionList = res.data.data.permission_list)
    }
  },
  created() {
    this.permissionList()
  }
}
</script>

<style lang="sass" scoped>
.carousel-width
  width: 96vw

  @media (min-width: 1024px) 
    .carousel-width
      width: auto
</style>